import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { ArrowDirection, ArrowIcon, Button, ButtonType } from '../atoms';
export var UnsubscribeChatbotChoiceButton = function (_a) {
    var index = _a.index, choices = _a.choices, disabled = _a.disabled, answeredIDs = _a.answeredIDs, onClickChoice = _a.onClickChoice;
    var isIncludeAnswered = function (choice, choiceIndex) {
        return answeredIDs.some(function (v) { return v.choiceID == choice.id && v.choiceIndex == choiceIndex; });
    };
    return (React.createElement("div", { className: css(styles.container) }, choices.map(function (choice, i) { return (React.createElement(Button, { key: i, buttonType: isIncludeAnswered(choice, index) ? ButtonType.darkPink : ButtonType.darkPinkOutline, className: css(styles.choiceButton), onClick: function () { return onClickChoice(choice, index); }, disabled: disabled },
        React.createElement("div", { className: css(styles.buttonText) }, choice.text),
        React.createElement(ArrowIcon, { classObject: styles.arrowIcon, colorClassObject: styles.arrowIconColor, direction: ArrowDirection.right }))); })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    choiceButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        gap: 10,
        padding: '6px 8px',
    },
    buttonText: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '160%',
    },
    arrowIcon: {
        marginBottom: 1,
    },
    arrowIconColor: {
        fill: 'currentColor',
    },
});
