import * as React from 'react';
import { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { ArrowIcon, ArrowDirection } from '../atoms';
import { PlanAmountText } from '../atoms/plan_amount_text';
export var CcPlanSubsection = function (_a) {
    var basePlans = _a.basePlans, selectedPlan = _a.selectedPlan, planName = _a.planName, onClickPlan = _a.onClickPlan;
    var _b = useState(false), isOpenPlanDetail = _b[0], setIsOpenPlanDetail = _b[1];
    var basePlanAmountDiff = function () {
        return basePlans
            .map(function (v) { return (v.plan.isAnnualPlan() ? -v.plan.amountPerAnnual : v.plan.amountPerAnnual); })
            .reduce(function (sum, num) { return sum + num; }, 0);
    };
    var onClickPlanDetail = function () {
        setIsOpenPlanDetail(!isOpenPlanDetail);
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.planFlex), onClick: onClickPlanDetail, "data-test": "openSelectPlan" },
            React.createElement("div", { className: css(styles.planText) },
                React.createElement("div", { className: css(styles.planTitle) }, "\u30D7\u30E9\u30F3"),
                !isOpenPlanDetail && React.createElement(PlanAmountText, { planName: planName, plan: selectedPlan })),
            React.createElement(ArrowIcon, { direction: isOpenPlanDetail ? ArrowDirection.top : ArrowDirection.bottom, color: Colors.Black })),
        isOpenPlanDetail && (React.createElement("div", { className: css(styles.planDetail) }, basePlans.map(function (v, i) { return (React.createElement("div", { key: i, className: css(styles.planBox, selectedPlan.id === v.plan.id && styles.planBoxSelected, v.plan.isAnnualPlan() && styles.planBoxWithHeader), onClick: function () { return onClickPlan(v); }, "data-test": v.plan.name }, v.plan.isAnnualPlan() ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.planBoxHeader) },
                "1\u5E74\u3067",
                basePlanAmountDiff(),
                "\u5186\u304A\u30C8\u30AF\uFF01"),
            React.createElement("div", { className: css(styles.planBoxTitle) }, v.plan.name),
            React.createElement("div", { className: css(styles.planBoxDescription) },
                v.plan.amountWithoutTax.toLocaleString(),
                "\u5186",
                React.createElement("div", { className: css(styles.planBoxAnnotation) },
                    "(\u7A0E\u8FBC",
                    v.plan.amount.toLocaleString(),
                    "\u5186)/\u5E74")),
            React.createElement("div", { className: css(styles.planBoxDescription) },
                "1\u30F6\u6708\u3042\u305F\u308A",
                v.plan.amountPerMonthWithoutTax.toLocaleString(),
                "\u5186",
                React.createElement("div", { className: css(styles.planBoxAnnotation, styles.planBoxAnnotationSmall) },
                    "(\u7A0E\u8FBC",
                    v.plan.amountPerMonth.toLocaleString(),
                    "\u5186)")))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.planBoxTitle) }, v.plan.name),
            React.createElement("div", { className: css(styles.planBoxDescription) },
                v.plan.amountPerMonthWithoutTax.toLocaleString(),
                "\u5186",
                React.createElement("div", { className: css(styles.planBoxAnnotation) },
                    "(\u7A0E\u8FBC",
                    v.plan.amountPerMonth.toLocaleString(),
                    "\u5186)/\u6708")))))); })))));
};
var styles = StyleSheet.create({
    container: {
        marginTop: 42,
        padding: '20px 0',
        borderTop: "1px solid ".concat(Colors.BorderLightGray),
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
    planFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        cursor: 'pointer',
    },
    planText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 10,
        flex: '1 0 0',
    },
    planTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    planDetail: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        marginTop: 16,
    },
    planBox: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        gap: 2,
        padding: '16px 21px 13px',
        borderRadius: 10,
        border: "2px solid ".concat(Colors.BorderLightGray),
        cursor: 'pointer',
    },
    planBoxSelected: {
        border: "3px solid ".concat(Colors.PrimaryDark),
    },
    planBoxWithHeader: {
        marginTop: 14,
    },
    planBoxHeader: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        color: Colors.White,
        backgroundColor: Colors.PrimaryDark,
        height: 25,
        top: -15,
        left: -3,
        padding: '4px 8px',
        borderRadius: '12px 12px 12px 0',
    },
    planBoxTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 0.8,
    },
    planBoxDescription: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.8,
    },
    planBoxAnnotation: {
        fontSize: 10,
        letterSpacing: 0.5,
        height: 22,
    },
    planBoxAnnotationSmall: {
        height: 20,
    },
});
