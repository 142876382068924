import { useEffect, useState } from 'react';
import * as dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
export var SECONDS_OF_ONE_MINUTE = 60;
export var MINUTES_OF_ONE_HOUR = 60;
export var DAYS_OF_ONE_WEEK = 7;
export var ONE_DAY_IN_MS = 86400000;
export var ONE_WEEK_IN_MS = 604800000;
export var MAX_DATE_TIMESTAMP = 4102326000000; // 2099-12-31 00:00:00
export var isToday = function (year, month, date) {
    var d = new Date();
    return d.getDate() === date && d.getMonth() + 1 === month && d.getFullYear() === year;
};
export var dateIsToday = function (date) {
    return dayjs(date).isToday();
};
export var isYesterday = function (year, month, date) {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    return d.getDate() === date && d.getMonth() + 1 === month && d.getFullYear() === year;
};
// 一週間以内かどうか判定する.(将来の日付の場合false)
export var isWithinOneWeek = function (year, month, date) {
    var now = new Date();
    now.setHours(23, 59, 59, 999); // 当日 23:59:59
    var d = new Date(year, month - 1, date);
    var dateDiff = (now.getTime() - d.getTime()) / (1000 * 60 * 60 * 24);
    return dateDiff < 7 && dateDiff >= 0;
};
export var getLastDayOfMonth = function (y, m) {
    return new Date(y, m, 0).getDate();
};
// 指定した日付が今日から指定日数以内かどうか判定する
export var isWithinDaysFromToday = function (date, dayCount) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var targetDate = new Date(date);
    targetDate.setHours(0, 0, 0, 0);
    var thresholdDate = new Date(today);
    thresholdDate.setDate(today.getDate() + dayCount);
    return targetDate.getTime() <= thresholdDate.getTime();
};
// 日付のフォーマットを行う。（出力例：2019-01-25 12:50:30）
export var getFormatedDateTime = function (date) {
    return (date.getFullYear() +
        '-' +
        appendLeadingZeroes(date.getMonth() + 1) +
        '-' +
        appendLeadingZeroes(date.getDate()) +
        ' ' +
        appendLeadingZeroes(date.getHours()) +
        ':' +
        appendLeadingZeroes(date.getMinutes()) +
        ':' +
        appendLeadingZeroes(date.getSeconds()));
};
// 日付が一桁の場合に頭に「0」を付与する（例：5 → 05）
var appendLeadingZeroes = function (n) {
    return n < 10 ? "0".concat(n) : n;
};
export var getUnixTimeInUTC = function (date) {
    // new Dateはロケーションを自動的に判断して時間を返す。
    // UTCにするためにタイムゾーンを取得してオフセットタイムを
    // 調整してUTC時間を取得する
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};
export var getDiffDays = function (countdownEndDate, nowDate) {
    // 日付の差分を見たいので、対象時間を0時にする
    return dayjs(countdownEndDate).startOf('date').diff(nowDate, 'd');
};
export var isNowAfter = function (msUnixtime) {
    return Date.now() > msUnixtime;
};
export var sameDay = function (targatDate, nowDate) {
    var parsedTargatDate = "".concat(targatDate.getFullYear(), "-").concat(targatDate.getMonth(), "-").concat(targatDate.getDate());
    var parsedNowDate = "".concat(nowDate.getFullYear(), "-").concat(nowDate.getMonth(), "-").concat(nowDate.getDate());
    return parsedTargatDate === parsedNowDate;
};
export var exceedDay = function (msUnixtime) {
    return Date.now() - msUnixtime > ONE_DAY_IN_MS;
};
export var exceedWeek = function (msUnixtime) {
    return Date.now() - msUnixtime > ONE_WEEK_IN_MS;
};
export var convertUnixToDate = function (unixtime) {
    return new Date(unixtime * 1000);
};
// YYYY.M.D の形式にフォーマット(例: 2021.4.11)
export var formatedDate = function (unixtime) {
    var date = convertUnixToDate(unixtime).toLocaleDateString();
    var formatedDate = dayjs(date).format('YYYY.M.D');
    return formatedDate;
};
export var getLastWeekMonday = function () {
    var date = new Date();
    var lastWeekMonday = new Date();
    var dayNum = date.getDay() == 0 ? 7 : date.getDay() + 6;
    lastWeekMonday.setDate(date.getDate() - dayNum);
    return lastWeekMonday;
};
// 日付として正しいかチェックする
// date: '2020-02-31'
// format: 'YYYY-MM-DD'
// dayjsのisValidだとformatまでしか確認してくれないため、2020-02-31だとtrueになってしまわないようにdateとの比較を追加
export var validateDate = function (date, format) {
    var splitDate = date.split('-', 3);
    if (Number.isNaN(parseInt(splitDate[0], 10)) ||
        Number.isNaN(parseInt(splitDate[1], 10)) ||
        Number.isNaN(parseInt(splitDate[2], 10))) {
        return false;
    }
    var dayjsDate = dayjs(date, format);
    return dayjsDate.isValid() && dayjsDate.format(format) === date;
};
// DateをYYYY年MM月DD日形式に変換する
export var dateToYearMonthDayString = function (date) {
    return "".concat(date.getFullYear(), "\u5E74").concat(date.getMonth() + 1, "\u6708").concat(date.getDate(), "\u65E5");
};
// YYYY年MM月の形式に変換する
export var getFormatedYearMonth = function (year, month) {
    var yearMonth = new Date(year, month - 1);
    return "".concat(yearMonth.getFullYear(), "\u5E74").concat(yearMonth.getMonth() + 1, "\u6708");
};
// タイムスタンプからYYYY年MM月の形式に変換する
// "2020-09-16T13:06:28+09:00" を2020年09月
export var getFormatedTimestampToYearMonth = function (timestamp) {
    var yearMonth = new Date(timestamp);
    return "".concat(yearMonth.getFullYear(), "\u5E74").concat(yearMonth.getMonth() + 1, "\u6708");
};
// YYYY/MM/DD の形式にフォーマット(例: 2021/04/11)
export var getFormattedToSlashFormat = function (unixtime) {
    var date = convertUnixToDate(unixtime);
    return dayjs(date).format('YYYY/MM/DD');
};
// YYYYMMDD の形式にフォーマット(例: 20210411)
export var getFormattedToFormat = function (date) {
    return dayjs(date).format('YYYYMMDD');
};
// YYYY-MM-DD の形式にフォーマット(例: 2021-04-11)
export var getFormattedDateToHyphenFormat = function (date) {
    return dayjs(date).format('YYYY-MM-DD');
};
// YYYYMMDD 形式から M月D日 ddd曜日へと変換
export var getFormattedToMonthDateDay = function (date) {
    return dayjs(date).locale('ja').format('M月D日 ddd曜日');
};
// YYYYMMDD 形式から M月D日 へと変換
export var getFormattedToMonthDate = function (date) {
    return dayjs(date).locale('ja').format('M月D日');
};
// 今年以外の日付は年度を含めて YYYY年 M月D日 ddd曜日を返す
export var getFormattedWithPastYear = function (date) {
    if (date.getFullYear() === new Date().getFullYear()) {
        return dayjs(date).locale('ja').format('M月D日 ddd曜日');
    }
    else {
        return dayjs(date).locale('ja').format('YYYY年 M月D日 ddd曜日');
    }
};
// YYYYMMDD 形式から Dateへと変換
export var getFormatToDate = function (dateString) {
    return dayjs(dateString, 'YYYYMMDD').locale('ja').toDate();
};
// 文字列を date を使わずに YYYY-MM-DD の形式にフォーマット(例: 2021-04-11)
export var getFormattedToHyphenFormat = function (y, m, d) {
    return "".concat(y, "-").concat(('00' + parseInt(m, 10)).slice(-2), "-").concat(('00' + parseInt(d, 10)).slice(-2));
};
export var getCurrentUnixTimeInSeconds = function () { return Math.round(new Date().getTime() / 1000); };
// 8/9 の形式にフォーマット
export var getFormatedDate = function (date) { return "".concat(date.getMonth() + 1, "/").concat(date.getDate()); };
// Y/m/d 形式にフォーマット
export var getFormattedDateWithYear = function (date) {
    return "".concat(date.getFullYear(), "/").concat(date.getMonth() + 1, "/").concat(date.getDate());
};
export var dayOfWeekEnglish = function (date) { return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]; };
// xx時間oo分 の形式にフォーマット(例: 1時間22分)
export var getStringDuration = function (minutes) {
    var hours = Math.floor(minutes / MINUTES_OF_ONE_HOUR);
    var remainder = minutes % MINUTES_OF_ONE_HOUR;
    var result = '';
    if (hours > 0)
        result += "".concat(hours, "\u6642\u9593");
    if (remainder > 0)
        result += "".concat(remainder, "\u5206");
    return result;
};
// xx日前 の形式にフォーマット(例: 17日前, 20時間前)
export var getDiffFromCurrent = function (msUnixtime) {
    var diffSeconds = Math.floor((new Date().getTime() - msUnixtime) / 1000);
    var diffMinutes = Math.floor(diffSeconds / 60);
    var diffHours = Math.floor(diffMinutes / 60);
    var diffDay = Math.floor(diffHours / 24);
    var diffMonth = Math.floor(diffDay / 30);
    if (diffSeconds < 60)
        return "".concat(diffSeconds, "\u79D2\u524D");
    if (diffMinutes < 60)
        return "".concat(diffMinutes, "\u5206\u524D");
    if (diffHours < 24)
        return "".concat(diffHours, "\u6642\u9593\u524D");
    if (diffDay < 30)
        return "".concat(diffDay, "\u65E5\u524D");
    return "".concat(diffMonth, "\u30F6\u6708\u524D");
};
// 8:06 の形式にフォーマット
export var toTimeFromUnix = function (unixtime) {
    var date = convertUnixToDate(unixtime);
    return "".concat(date.getHours().toString(), ":").concat(date.getMinutes().toString().padStart(2, '0'));
};
// yyyy-m-d の形式にフォーマット
export var toYearMonthDayFromDate = function (date) { return "".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate()); };
// hooks 現在時刻のunixtime
export var useNowUnixtime = function () {
    var _a = useState(dayjs().unix()), now = _a[0], setNow = _a[1];
    useEffect(function () {
        var timeId = setInterval(function () { return setNow(function (v) { return v + 1; }); }, 1000);
        return function () { return clearInterval(timeId); };
    }, []);
    return now;
};
// unixtime の単位をミリ秒から秒に変換する
export var convertToSeconds = function (msUnixtime) {
    return Math.floor(msUnixtime / 1000);
};
export var getNearestFiveMinTime = function () {
    var now = new Date();
    return new Date(Math.ceil(now.getTime() / (5 * 60 * 1000)) * (5 * 60 * 1000));
};
// 土曜日基準で何週間前かを返す
// 例: now = 5/24(金)の場合
// | date が 5/19(日) 以降     => 0
// | date が 5/12(日) - 18(土) => 1
// | date が 5/5(日)  - 11(土) => 2
export var weeksFromToday = function (date) {
    var now = dayjs();
    var thisWeekSunday = now.add(6 - now.day(), 'd');
    return thisWeekSunday.diff(dayjs(date), 'w');
};
export var dateMaxToday = function (date) {
    var today = new Date();
    var todayjs = dayjs(today);
    if (todayjs.isBefore(date)) {
        return today;
    }
    else {
        return date;
    }
};
