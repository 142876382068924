import * as React from 'react';
import 'dayjs/locale/ja';
import { useNavigate } from 'react-router';
import { css, StyleSheet } from 'aphrodite';
import { routes } from '@lean-body/src/route';
import { useWindowSize, useSmMediaQuery, useXlMediaQuery } from '@lean-body/src/util';
import { Colors, MediaBreakPointUp, drawerWidth } from '@lean-body/components/styles';
import { TodaysCourseLessonsHeader } from '@lean-body/components/molecules';
import { VerticalListArrows } from '../molecules/vertical_list_arrows';
import { LessonComponent } from '../molecules/lesson_component';
import { HOME_SECTION_MARGIN_LEFT_TAB } from '../constants';
export var TodaysCourseLessons = function (_a) {
    var _b, _c, _d;
    var course = _a.course, courseDay = _a.courseDay, isSampleCourse = _a.isSampleCourse, apiClient = _a.apiClient, classObject = _a.classObject, tracker = _a.tracker;
    var isSM = useSmMediaQuery();
    var isXL = useXlMediaQuery();
    var navigate = useNavigate();
    var windowSize = useWindowSize();
    var itemSize = isSM
        ? windowSize.width - 42
        : !isXL
            ? windowSize.width - drawerWidth.md - HOME_SECTION_MARGIN_LEFT_TAB * 2 - 15
            : windowSize.width - drawerWidth.xl - HOME_SECTION_MARGIN_LEFT_TAB * 2 - 15;
    var onClickCourse = function () {
        tracker.trackClickEvent({
            category: 'Click Todays Course',
            label: "courseID: ".concat(course.id),
        });
        navigate(routes.COURSE.compile({ id: String(course.id) }));
    };
    var onClickLesson = function (lesson) {
        tracker.trackClickEvent({
            category: 'Lesson Clicks',
            label: "lessonID: ".concat(lesson.id),
        });
    };
    var styles = StyleSheet.create({
        container: {
            position: 'relative',
            padding: '12px 0 16px',
            border: "1.5px solid ".concat(Colors.BorderLightGray),
            borderRadius: 10,
        },
        header: (_b = {
                margin: '0 16px 12px'
            },
            _b[MediaBreakPointUp.MD] = {
                margin: '0 30px 12px',
            },
            _b),
        lessonCarousel: (_c = {
                paddingLeft: 16,
                paddingRight: 16
            },
            _c[MediaBreakPointUp.MD] = {
                paddingLeft: 30,
                paddingRight: 30,
            },
            _c),
        lessonImg: (_d = {
                width: '100%'
            },
            _d[MediaBreakPointUp.MD] = {
                width: 270,
            },
            _d[MediaBreakPointUp.XL] = {
                width: 335,
            },
            _d),
        pageCount: {
            marginTop: 5,
        },
    });
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(TodaysCourseLessonsHeader, { course: course, courseDay: courseDay, onClickCourse: onClickCourse, classObject: styles.header, showTitleOnly: isSM }),
        React.createElement(React.Fragment, null, isSampleCourse ? (React.createElement(LessonComponent, { lesson: courseDay.courseDaysLessons[0].lesson, courseDayLessonID: courseDay.courseDaysLessons[0].id, onClick: onClickLesson, addFavoriteLesson: function () { return apiClient.addFavoriteLesson(courseDay.courseDaysLessons[0].lesson.id); }, unFavoriteLesson: function () { return apiClient.unFavoriteLesson(courseDay.courseDaysLessons[0].lesson.id); }, isMin: false, isVertical: !isSM, classObject: styles.lessonCarousel })) : (React.createElement(VerticalListArrows, { itemSize: itemSize, itemsPadding: 0, itemMarginRight: !isSM && 16, isPageCount: true, classObject: styles.lessonCarousel, pageCountClassObject: isSM && styles.pageCount, items: courseDay.courseDaysLessons.map(function (v) { return (React.createElement(LessonComponent, { lesson: v.lesson, courseDayLessonID: v.id, onClick: onClickLesson, addFavoriteLesson: function () { return apiClient.addFavoriteLesson(v.lesson.id); }, unFavoriteLesson: function () { return apiClient.unFavoriteLesson(v.lesson.id); }, isMin: false, isVertical: !isSM, hideFavoriteButton: true, hideFavoriteCount: isSM, hideDifficulty: isSM, hideTags: isSM, hideDescription: isSM, headImgClassObject: styles.lessonImg })); }) })))));
};
