// WebViewに送信できるコールバックハンドラー名
export var WebViewCallbackHandlers = {
    notificationSettingsChange: 'handle_notification_settings_change',
    registrationOpenBrowser: 'registration_open_browser',
    // ヘルスコネクトがインストールされていない -> インストール催促のダイアログを表示
    // ヘルスコネクトのPermissionに回答したことがない -> Permissionを表示
    // ヘルスコネクトのPermissionに回答済み(許可/拒否どちらであっても) -> ヘルスコネクトアプリを表示
    requestHealthConnectPermission: 'request_health_connect_permission',
    fbLogin: 'fb_login',
    requestDeviceNotificationPermission: 'request_device_notification_permission', // デバイスの通知許可ダイアログを表示する
    requestDeviceNotificationPermissionSettings: 'request_device_notification_permission_settings', // デバイスの通知許可設定ページを表示する
    loadNotificationSettings: 'load_notification_settings', // 通知設定を取得する
    requestCameraPermission: 'request_camera_permission', // カメラの許可
    openLeanBodyAppSetting: 'open_lean_body_app_setting', // アプリの設定を開く
};
// WebViewから受け取るイベント
export var WebViewEvents = {
    deviceNotificationPermission: 'webview_device_notification_permission',
};
