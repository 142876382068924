export var Alliance = {
    MEGALOS: 'megalos',
};
var PLAN_TAX = 1.1;
var Plan = /** @class */ (function () {
    function Plan(planData, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stripePlanId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "planType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "freeTrialDays", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextPlanId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "corporationId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alliance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "periodEndFromNow", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentChannel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = planData.id;
        this.stripePlanId = planData.stripePlanId;
        this.name = planData.name;
        this.amount = planData.amount;
        this.planType = planData.planType;
        this.freeTrialDays = planData.freeTrialDays;
        this.isActive = planData.isActive;
        this.startAt = planData.startAt;
        this.nextPlanId = planData.nextPlanId;
        this.corporationId = planData.corporationId;
        this.alliance = planData.alliance;
        this.thumbnailSrc = planData.thumbnailSrc ? config.cdnBaseURL + planData.thumbnailSrc : null;
        this.periodEndFromNow = planData.periodEndFromNow;
        this.paymentChannel = planData.paymentChannel;
        if (planData.nextPlan) {
            this.nextPlan = new Plan(planData.nextPlan, config);
        }
    }
    Object.defineProperty(Plan.prototype, "isPlanAfter2024May15PriceChange", {
        get: function () {
            // stripe_plan_idの末尾にプラン作成日を入れてるので、日付で最新のプランか判定しています
            return this.stripePlanId.includes('2024_05_15');
        },
        enumerable: false,
        configurable: true
    });
    // 12ヶ月プランかどうか判定
    Object.defineProperty(Plan.prototype, "isAnnualPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.planType === 'annual';
        }
    });
    // 6ヶ月プランかどうか判定
    Object.defineProperty(Plan.prototype, "isSixMonthPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.planType === 'six_month';
        }
    });
    // 3ヶ月プランかどうか判定
    Object.defineProperty(Plan.prototype, "isThreeMonthPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.planType === 'three_month';
        }
    });
    // 月額プランかどうか判定
    Object.defineProperty(Plan.prototype, "isStandardPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.planType === 'standard';
        }
    });
    // 100円キャンペーンプランかどうか判定
    Object.defineProperty(Plan.prototype, "is100YenCampaignPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.stripePlanId === '100-yen-campaign';
        }
    });
    Object.defineProperty(Plan.prototype, "isCampaignPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return !!this.nextPlanId || this.stripePlanId.includes('campaign');
        }
    });
    // フリートライアルがあるかどうか
    Object.defineProperty(Plan.prototype, "hasFreeTrial", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.freeTrialDays !== 0;
        }
    });
    // 割引率を計算
    Object.defineProperty(Plan.prototype, "discountPercent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (targetAmount) {
            return Math.floor(-((this.amount / targetAmount) * 100) + 100);
        }
    });
    // 引数で渡された支払いチャネルと現在の支払いチャネルが一致しているかどうか
    Object.defineProperty(Plan.prototype, "isPaymentChannelMatched", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (paymentChannel) {
            return this.paymentChannel === paymentChannel;
        }
    });
    Object.defineProperty(Plan.prototype, "amountPerMonth", {
        // 月々の相当金額を計算
        get: function () {
            if (this.isAnnualPlan()) {
                return this.amount / 12;
            }
            else if (this.isSixMonthPlan()) {
                return this.amount / 6;
            }
            else if (this.isThreeMonthPlan()) {
                return this.amount / 3;
            }
            return this.amount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Plan.prototype, "amountPerAnnual", {
        // 年間の金額を計算
        get: function () {
            if (this.isAnnualPlan()) {
                return this.amount;
            }
            else if (this.isSixMonthPlan()) {
                return this.amount * 2;
            }
            else if (this.isThreeMonthPlan()) {
                return this.amount * 4;
            }
            return this.amount * 12;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Plan.prototype, "amountPerMonthWithoutTax", {
        get: function () {
            return Math.ceil(this.amountPerMonth / PLAN_TAX);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Plan.prototype, "amountWithoutTax", {
        get: function () {
            return Math.ceil(this.amount / PLAN_TAX);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Plan.prototype, "freeTrialString", {
        get: function () {
            if (!this.hasFreeTrial())
                return null;
            if (this.freeTrialDays % 7 === 0)
                return "".concat(this.freeTrialDays / 7, "\u9031\u9593");
            return "".concat(this.freeTrialDays, "\u65E5\u9593");
        },
        enumerable: false,
        configurable: true
    });
    // キャンペーンプランの場合に次の変更予定プランがシステム側で自動決定している場合(= nextPlanIDが設定されている場合)はプラン変更取り消しボタンを表示しない
    Object.defineProperty(Plan.prototype, "canCancelPlanChanging", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return !this.nextPlanId;
        }
    });
    // 法人プランかどうか
    Object.defineProperty(Plan.prototype, "isCorporationPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return !!this.corporationId;
        }
    });
    return Plan;
}());
export { Plan };
var AmazonPayPlan = /** @class */ (function () {
    function AmazonPayPlan(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "planType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "freeTrialDays", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextPlanId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "signature", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "payload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.name = data.name;
        this.amount = data.amount;
        this.planType = data.planType;
        this.freeTrialDays = data.freeTrialDays;
        this.nextPlanId = data.nextPlanId;
        this.signature = data.signature;
        this.payload = data.payload;
    }
    return AmazonPayPlan;
}());
export { AmazonPayPlan };
var BasePlan = /** @class */ (function () {
    function BasePlan(data, config) {
        var _a;
        Object.defineProperty(this, "plan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amazonPayPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.plan = new Plan(data.plans[0], config);
        if ((_a = data.amazonPayPlans) === null || _a === void 0 ? void 0 : _a.length) {
            this.amazonPayPlan = new AmazonPayPlan(data.amazonPayPlans[0]);
        }
    }
    return BasePlan;
}());
export { BasePlan };
