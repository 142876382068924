import * as React from 'react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { dateIsToday, dateMaxToday, getFormattedToFormat, useQuery } from '@lean-body/src/util';
import { TASK_DATE_QUERY, Task } from '@lean-body/src/domain';
import { TaskProgress } from '../organisms/task_progress';
import { PageLoading } from './page_loading';
import { StepsCalories } from './steps_calories';
import { EditGoal } from './edit_goal';
import { ActivityTabs, LifetimeActivityType } from './lifetime_activity_chart';
import { GrayBoxTabs, TabTypes } from '../molecules/gray_box_tabs';
import { DatePagination, DatePaginationStep } from '../molecules/date_pagination';
import { LifetimeTaskActivity } from './lifetime_task_activity';
import { ArrowIconWithMargin, ArrowDirection } from '../atoms';
import { Colors } from '../styles';
export var StepPageTypes = {
    list: 'list',
    goalEdit: 'goal_edit',
};
export var StepTask = function (_a) {
    var apiClient = _a.apiClient, classObject = _a.containerClassObject, headerClassObject = _a.headerClassObject, arrowIconClassObject = _a.arrowIconClassObject, onClickBackHome = _a.onClickBackHome;
    var query = useQuery();
    var today = new Date();
    var queryDate = dayjs(query.get(TASK_DATE_QUERY) || today).toDate();
    var _b = useState(StepPageTypes.list), pageType = _b[0], setPageType = _b[1];
    var _c = React.useState(ActivityTabs.daily), tab = _c[0], setTab = _c[1];
    var _d = useState(null), dailyStepsTask = _d[0], setDailyStepsTask = _d[1];
    var _e = useState(), value = _e[0], setValue = _e[1];
    var _f = useState(), stepGoals = _f[0], setStepGoals = _f[1];
    var _g = useState(queryDate), date = _g[0], setDate = _g[1];
    var _h = useState(DatePaginationStep.day), datePaginationStep = _h[0], setDatePaginationStep = _h[1];
    useEffect(function () {
        if (pageType === StepPageTypes.list) {
            apiClient.fetchDailyStepsTask(getFormattedToFormat(date)).then(function (v) {
                setDailyStepsTask(v);
            });
        }
        else if (pageType === StepPageTypes.goalEdit) {
            apiClient.fetchStepGoals().then(function (fetchStepGoals) {
                var _a;
                var stepGoalList = fetchStepGoals.map(function (v) {
                    return { name: v.targetSteps.toString(), value: v.targetSteps.toString() };
                });
                setValue((_a = stepGoalList.find(function (v) { return v.name === Task.roundTaskCalories(dailyStepsTask.steps.goal).toString(); })) === null || _a === void 0 ? void 0 : _a.value);
                setStepGoals(stepGoalList);
            });
        }
    }, [pageType, date]);
    React.useEffect(function () {
        onChangeTab(tab);
    }, [tab]);
    var onChangeTab = function (key) {
        switch (key) {
            case ActivityTabs.weekly:
                setDatePaginationStep(DatePaginationStep.week);
                break;
            case ActivityTabs.monthly:
                setDatePaginationStep(DatePaginationStep.month);
                break;
            case ActivityTabs.quarterly:
                setDatePaginationStep(DatePaginationStep.quarter);
                break;
            default:
                setDatePaginationStep(DatePaginationStep.day);
                return;
        }
    };
    var onClickEditStepGoal = function () {
        setPageType(StepPageTypes.goalEdit);
    };
    var onClickBack = function () {
        setPageType(StepPageTypes.list);
    };
    var onSaveStepGoal = function (v) {
        apiClient.saveStepGoal(Number(v)).then(function () {
            setPageType(StepPageTypes.list);
            setValue(v);
        });
    };
    var onClickDatePagination = function (value) {
        setDate(dateMaxToday(value));
    };
    var isLoading = function () {
        if (pageType === StepPageTypes.goalEdit) {
            return !stepGoals;
        }
        else {
            return !dailyStepsTask;
        }
    };
    if (isLoading()) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(classObject) }, pageType === StepPageTypes.list ? (React.createElement(React.Fragment, null,
        React.createElement(ArrowIconWithMargin, { classObject: arrowIconClassObject, direction: ArrowDirection.left, color: Colors.TextDark, onClick: function () {
                onClickBackHome(queryDate);
            } }),
        React.createElement("div", { className: css(headerClassObject) }, "\u30A6\u30A9\u30FC\u30AD\u30F3\u30B0"),
        React.createElement(GrayBoxTabs, { setTab: setTab, tab: tab, tabType: TabTypes.activity, classObject: styles.tab }),
        React.createElement(DatePagination, { value: date, max: today, step: datePaginationStep, onClickNext: onClickDatePagination, onClickPrev: onClickDatePagination, classObject: styles.datePagination }),
        tab === ActivityTabs.daily ? (React.createElement(React.Fragment, null,
            React.createElement(TaskProgress, { current: Task.roundTaskCalories(dailyStepsTask.steps.current), goal: Task.roundTaskCalories(dailyStepsTask.steps.goal), unitLabel: "\u6B69", classObject: styles.progressContainerSteps, showEditButton: dateIsToday(date), onClickButton: onClickEditStepGoal }),
            React.createElement(StepsCalories, { stepActivity: dailyStepsTask.stepActivity, classObject: styles.stepsCaloriesInfo }))) : (React.createElement(LifetimeTaskActivity, { activityType: LifetimeActivityType.steps, apiClient: apiClient, tab: tab, goalDate: date, classObject: styles.lifetimeStepActivity })))) : (pageType === StepPageTypes.goalEdit && (React.createElement(EditGoal, { title: "1\u65E5\u306E\u76EE\u6A19\u6B69\u6570", unit: "\u6B69", defaultValue: value, options: stepGoals, onClickBack: onClickBack, onSave: onSaveStepGoal })))));
};
var styles = StyleSheet.create({
    tab: {
        marginBottom: 20,
    },
    datePagination: {
        marginBottom: 14,
    },
    progressContainerSteps: {
        margin: '24px 0 60px 0',
    },
    stepsCaloriesInfo: {
        width: '100%',
    },
    lifetimeStepActivity: {
        width: 327,
        margin: '0 auto',
    },
});
