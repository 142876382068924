import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Button } from '@lean-body/components/atoms';
import { Colors } from '@lean-body/components/styles';
import { AmazonPayButton } from '../atoms/amazon_pay_button';
import { CCFormSections } from '@lean-body/components/molecules';
export var CCFormButton = function (_a) {
    var buttonType = _a.buttonType, disabled = _a.disabled, loading = _a.loading, submitLabel = _a.submitLabel, basePlan = _a.basePlan, onClick = _a.onClick, campaign = _a.campaign, dataTest = _a.dataTest, config = _a.config, selectedSection = _a.selectedSection, signedInAmazonPay = _a.signedInAmazonPay;
    var amazonPayPlan = basePlan === null || basePlan === void 0 ? void 0 : basePlan.amazonPayPlan;
    return (React.createElement("div", { className: css(styles.container) }, selectedSection === CCFormSections.stripe || signedInAmazonPay ? (React.createElement(Button, { buttonType: buttonType, disabled: disabled, loading: loading, dataTest: dataTest || 'submitCCForm', fullWidth: true, className: css(styles.button), onClick: onClick }, submitLabel)) : (selectedSection === CCFormSections.amazonPay &&
        amazonPayPlan && (React.createElement(AmazonPayButton, { plan: amazonPayPlan, config: config, classObject: styles.amazonPayButton, campaign: campaign })))));
};
var styles = StyleSheet.create({
    container: {
        marginTop: 12,
    },
    button: {
        fontSize: 16,
    },
    amount: {
        fontSize: 12,
        fontWeight: 'normal',
        color: Colors.Black,
        margin: '12px auto 0',
        textAlign: 'center',
    },
    amazonPayButton: {
        marginTop: 14,
    },
});
