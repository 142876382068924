import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ArrowIcon, ArrowDirection, CourseCompleteCrownIcon } from '@lean-body/components/atoms';
import { Colors } from '@lean-body/components/styles';
import { CourseVolumeProgress } from './course_volume_progress';
// TODO: チャットbot切り替え後に削除
var COURSE_TITLE_ID = 'smashLeanBodyCourseTitleId'; // 退会チャットボット（株式会社Smash）で使用しているので変更した際は、連絡する
export var TodaysCourseLessonsHeader = function (_a) {
    var course = _a.course, courseDay = _a.courseDay, classObject = _a.classObject, onClickCourse = _a.onClickCourse, isSampleCourse = _a.isSampleCourse, showTitleOnly = _a.showTitleOnly;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.headerItem), onClick: onClickCourse },
            React.createElement("div", { className: css(styles.detail) },
                React.createElement("div", { className: css(styles.title), id: isSampleCourse ? '' : COURSE_TITLE_ID },
                    course.name,
                    React.createElement(ArrowIcon, { direction: ArrowDirection.right, color: Colors.Black, classObject: styles.dialogArrow })),
                courseDay && !showTitleOnly && (React.createElement("div", null,
                    React.createElement("div", { className: css(styles.volumeDetail) },
                        React.createElement("div", { className: css(styles.volumeDescription) },
                            "Vol.",
                            courseDay.volume.volumeNumber,
                            " ",
                            courseDay.volume.title),
                        React.createElement("div", null,
                            "DAY",
                            courseDay.day))))),
            React.createElement("div", { className: css(styles.progress) }, courseDay && !showTitleOnly && (React.createElement(React.Fragment, null, courseDay.volume.isFirstCompleted ? (React.createElement(CourseCompleteCrownIcon, null)) : (React.createElement(CourseVolumeProgress, { totalDays: courseDay.volume.totalDays, finishedDays: courseDay.volume.finishedDays }))))))));
};
var styles = StyleSheet.create({
    container: {},
    headerItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '200%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.08em',
        color: Colors.Black,
    },
    detail: {},
    volumeDetail: {
        fontWeight: 700,
        fontSize: 12,
        lineHeight: '160%',
        letterSpacing: '0.04em',
        color: Colors.GrayDarkest,
    },
    volumeDescription: {
        maxHeight: 38,
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
    },
    dialogArrow: {
        height: 16,
        width: 16,
        marginLeft: 10,
    },
    progress: {
        marginLeft: 10,
    },
});
