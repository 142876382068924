var ChatbotDescription = /** @class */ (function () {
    function ChatbotDescription(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "iconUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "text", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "imageUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "imageLinkUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isUnsubscribeButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextSectionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "linkUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.iconUrl = data.iconUrl;
        this.text = data.text;
        this.imageUrl = data.imageUrl;
        this.imageLinkUrl = data.imageLinkUrl;
        this.isUnsubscribeButton = data.isUnsubscribeButton;
        this.nextSectionID = data.nextSectionId;
        this.linkUrl = data.linkUrl;
        this.orderIndex = data.orderIndex;
    }
    return ChatbotDescription;
}());
export { ChatbotDescription };
