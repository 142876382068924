var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import bind from 'bind-decorator';
import * as React from 'react';
import { NotFoundPage, NotFoundPageVM } from './404';
import { AppleCallbackPage, AppleCallbackPageVM } from './apple_callback';
import { CCRegistrationPage, CCRegistrationPageVM } from './cc_registration';
import { GoalMatchingAssessmentPage } from './main_goal_matching_assessment';
import { CheckEmailPage } from './check_email';
import { EmailVerificationPage, EmailVerificationPageVM } from './email_verification';
import { FutureUserPage } from './future_user';
import { LineCallbackPage, LineCallbackPageVM } from './line_callback';
import { LPIframePage, LPIframePageVM } from './lp_iframe';
import { MagazineUnsubscribePage, MagazineUnsubscribePageVM } from './magazine_unsubscribe';
import { Main, MainPageVM } from './main';
import { ActivityPage, ActivityPageVM } from './main_activity';
import { MainAnnouncementDetail, MainAnnouncementDetailVM } from './main_announcement_detail';
import { MainAnnouncementList, MainAnnouncementListVM } from './main_announcement_list';
import { UnsubscribeConfirmPage } from './main_unsubscribe_confirm';
import { HomePage } from './main_home';
import { LessonPage, LessonPageViewModel } from './main_lesson';
import { LivePage, LivePageVM } from './main_live';
import { LiveSchedulePage, LiveSchedulePageVM } from './main_live_schedule';
import { MatchingAssessmentPage } from './main_matching_assessment';
import { MatchingAssessmentIntroPage } from './main_matching_assessment_intro';
import { MegalosLiveCalendarPage, MegalosLiveCalendarPageVM } from './main_megalos_live_calendar';
import { MainNewPlaylist, MainNewPlaylistVM } from './main_new_playlist';
import { PlanChangePage, PlanChangePageVM } from './main_plan_change';
import { PlanChangeUnsubscribeCpPage, PlanChangeUnsubscribeCpPageVM } from './main_plan_change_unsubscribe_cp';
import { OriginalPlaylistPage, OriginalPlaylistPageViewModel } from './main_original_playlist';
import { ProgramPage, ProgramPageViewModel } from './main_program';
import { MainSearchPage } from './main_search';
import { SearchResultPage } from './main_search_result';
import { MainTrainersTopPage, MainTrainersTopPageVM } from './main_trainers_top';
import { MainInstructorPage } from './main_instructor';
import { UnsubscribeCautionPage } from './main_unsubscribe_caution';
import { PasswordResetPage, PasswordResetPageVM } from './password_reset';
import { PasswordResetRequestPage, PasswordResetRequestPageVM } from './password_reset_request';
import { SignInPage, SignInPageVM } from './signin';
import { SignUpPage, SignUpPageVM } from './signup';
import { MainUserSeasonRankHelpLp } from './main_user_season_rank_help_lp';
import { MainInitialGuide } from './main_initial_guide';
import { LiveLessonVimeo, LiveLessonVimeoVM } from './main_live_lesson_vimeo';
import { DormantPage, DormantPageVM } from './main_dormant';
import { TwitterCallback, TwitterCallbackViewModel } from './twitter_callback';
import { MainBillysStudio } from './main_billys_studio';
import { SettingPage } from './main_setting';
import { MaintenancePage } from './main_maintenance';
import { CoursePage } from './main_course';
import { CourseVolumePage } from './main_course_volume';
import { AmazonPayCallback } from './amazon_pay_callback';
import { AmazonPayForSignupCallback } from './amazon_pay_for_signup_callback';
import { MainPrograms } from './main_programs';
import { MainPlaylists } from './main_playlists';
import { PlaylistDetail, PlaylistDetailPageViewModel } from './main_playlist_detail';
import { ChangeCoursePage } from './main_change_course';
import { CourseRewardPage } from './main_course_reward';
import { MainMyLesson } from './main_my_lesson';
import { useParams } from 'react-router-dom';
import { MainInitialGuideDetail } from './main_initial_guide_detail';
import { DailyTasksPage } from './main_daily_tasks';
import { AndroidAccountDeletion } from './android_account_deletion';
import { CoachingPlanUnsubscribePage } from './main_coaching_plan_unsubscribe';
import { MainRankingPage } from './main_ranking';
import { MainMerchandisePage } from './main_merchandise';
import { CoachingPage } from './main_coaching';
import { DailyTaskSubtaskPage } from './main_daily_task_subtask';
import { NutrientPage } from './nutrient_page';
import { MealScorePage } from './meal_score_page';
import { ChartTasks } from './main_chart_tasks';
import { InvitationPage } from './main_invitation';
/**
 * PageComponentのProvider
 */
var PageProvider = /** @class */ (function () {
    function PageProvider(contentFactory, domainFactory, router, alertPresenter, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mainContentFactory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "domainFactory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mainVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "EmailVerificationVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "RecommendVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "MagazineUnsubscribeVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "SelectPlanVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        this.mainContentFactory = contentFactory;
        this.domainFactory = domainFactory;
        this.router = router;
        this.alertPresenter = alertPresenter;
        this.config = config;
    }
    /**
     * @returns @type {Main} のJSXElement
     */
    Object.defineProperty(PageProvider.prototype, "Main", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var contentFactory = this.mainContentFactory;
            if (!this.mainVM) {
                this.mainVM = new MainPageVM(this.domainFactory.AccountService(), this.domainFactory.AnnouncementService(), this.alertPresenter, this.domainFactory.apiClient, this.domainFactory.config);
            }
            return React.createElement(Main, { contentFactory: contentFactory, vm: this.mainVM, tracker: this.domainFactory.Tracker() });
        }
    });
    /**
     * @returns @type {Maintenance} のJSXElement
     */
    Object.defineProperty(PageProvider.prototype, "Maintenance", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MaintenancePage, { apiClient: this.domainFactory.apiClient });
        }
    });
    /**
     * @returns @type {CCRegistrationPage} のJSXElement
     */
    Object.defineProperty(PageProvider.prototype, "PaymentRegistratoin", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new CCRegistrationPageVM(this.domainFactory.AccountService(), this.domainFactory.apiClient, this.alertPresenter, this.config, this.domainFactory.tagClient, this.domainFactory.adlocateClient, this.domainFactory.Tracker());
            return React.createElement(CCRegistrationPage, { vm: vm });
        }
    });
    Object.defineProperty(PageProvider.prototype, "LineCallback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new LineCallbackPageVM(this.domainFactory.AccountService(), this.domainFactory.lineClient, this.domainFactory.ABTestService(), this.router, this.config, this.domainFactory.Tracker());
            return React.createElement(LineCallbackPage, { vm: vm });
        }
    });
    Object.defineProperty(PageProvider.prototype, "AppleCallback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new AppleCallbackPageVM(this.domainFactory.AccountService(), this.router, this.alertPresenter, this.domainFactory.Tracker());
            return React.createElement(AppleCallbackPage, { vm: vm });
        }
    });
    Object.defineProperty(PageProvider.prototype, "AmazonPayCallback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(AmazonPayCallback, { apiClient: this.domainFactory.apiClient, accountService: this.domainFactory.AccountService(), signedCookieService: this.domainFactory.SignedCookieService(), tracker: this.domainFactory.Tracker(), adlocateClient: this.domainFactory.adlocateClient }));
        }
    });
    Object.defineProperty(PageProvider.prototype, "AmazonPayForSignupCallback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(AmazonPayForSignupCallback, { accountService: this.domainFactory.AccountService(), tracker: this.domainFactory.Tracker(), alertPresenter: this.alertPresenter }));
        }
    });
    /**
     * @returns @type {SignUpPage} のJSXElement
     */
    Object.defineProperty(PageProvider.prototype, "SignUp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new SignUpPageVM(this.domainFactory.AccountService(), this.domainFactory.apiClient, this.domainFactory.Tracker(), this.router, this.alertPresenter, this.config, this.domainFactory.adlocateClient, this.domainFactory.tagClient, this.domainFactory.lineClient, this.domainFactory.gaClient);
            return React.createElement(SignUpPage, { vm: vm });
        }
    });
    /**
     * @returns @type {SignInPage} のJSXElement
     */
    Object.defineProperty(PageProvider.prototype, "SignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new SignInPageVM(this.domainFactory.AccountService(), this.domainFactory.ABTestService(), this.alertPresenter, this.router, this.config, this.domainFactory.tagClient, this.domainFactory.lineClient, this.domainFactory.Tracker());
            return React.createElement(SignInPage, { vm: vm });
        }
    });
    /**
     * @returns @type {EmailVerificationPage} のJSXElement
     */
    Object.defineProperty(PageProvider.prototype, "EmailVerification", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.EmailVerificationVM) {
                this.EmailVerificationVM = new EmailVerificationPageVM(this.domainFactory.AccountService(), this.domainFactory.apiClient, this.router, this.alertPresenter);
            }
            return React.createElement(EmailVerificationPage, { vm: this.EmailVerificationVM });
        }
    });
    /**
     * @returns @type {PasswordResetRequestPage} のJSXElement
     */
    Object.defineProperty(PageProvider.prototype, "PasswordResetRequestPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new PasswordResetRequestPageVM(this.domainFactory.apiClient, this.alertPresenter);
            return React.createElement(PasswordResetRequestPage, { vm: vm });
        }
    });
    /**
     * @returns @type {PasswordResetPage} のJSXElement
     */
    Object.defineProperty(PageProvider.prototype, "PasswordResetPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new PasswordResetPageVM(this.domainFactory.apiClient, this.router, this.alertPresenter);
            return React.createElement(PasswordResetPage, { vm: vm });
        }
    });
    Object.defineProperty(PageProvider.prototype, "MagazineUnsubscribePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            if (!this.MagazineUnsubscribeVM) {
                this.MagazineUnsubscribeVM = new MagazineUnsubscribePageVM(params.token, this.domainFactory.apiClient, this.router);
            }
            return React.createElement(MagazineUnsubscribePage, { vm: this.MagazineUnsubscribeVM });
        }
    });
    Object.defineProperty(PageProvider.prototype, "LPIframePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new LPIframePageVM(this.domainFactory.AccountService(), this.config);
            return React.createElement(LPIframePage, { vm: vm });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "Main", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "Maintenance", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "PaymentRegistratoin", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "LineCallback", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "AppleCallback", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "AmazonPayCallback", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "AmazonPayForSignupCallback", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "SignUp", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "SignIn", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "EmailVerification", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "PasswordResetRequestPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "PasswordResetPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "MagazineUnsubscribePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], PageProvider.prototype, "LPIframePage", null);
    return PageProvider;
}());
export { PageProvider };
/**
 * MainContent component内でswitchされるページのProvider
 */
var MainContentProvider = /** @class */ (function () {
    function MainContentProvider(domainFactory, alertPresenter, router, config) {
        var _this = this;
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "domainFactory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "topPageVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "SearchVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "mainSearchPageVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "TrainersTopVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "TopPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a) {
                var profile = _a.profile, me = _a.me, setMe = _a.setMe, homeDay = _a.homeDay, setHomeDay = _a.setHomeDay;
                return (React.createElement(HomePage, { apiClient: _this.domainFactory.apiClient, twitterService: _this.domainFactory.TwitterService(), accountService: _this.domainFactory.AccountService(), tracker: _this.domainFactory.Tracker(), config: _this.config, profile: profile, me: me, setMe: setMe, homeDay: homeDay, setHomeDay: setHomeDay }));
            }
        });
        Object.defineProperty(this, "SettingPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a) {
                var profile = _a.profile, setProfile = _a.setProfile;
                return (React.createElement(SettingPage, { accountService: _this.domainFactory.AccountService(), apiClient: _this.domainFactory.apiClient, alertPresenter: _this.domainFactory.alertPresenter, config: _this.config, tracker: _this.domainFactory.Tracker(), profile: profile, setProfile: setProfile }));
            }
        });
        Object.defineProperty(this, "GoalMatchingAssessmentPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a) {
                var setProfile = _a.setProfile;
                return (React.createElement(GoalMatchingAssessmentPage, { apiClient: _this.domainFactory.apiClient, config: _this.config, setProfile: setProfile }));
            }
        });
        Object.defineProperty(this, "DailyTasksPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a) {
                var profile = _a.profile, setProfile = _a.setProfile, homeDay = _a.homeDay;
                var params = useParams();
                return (React.createElement(DailyTasksPage, { apiClient: _this.domainFactory.apiClient, taskName: params.taskName, profile: profile, setProfile: setProfile, homeDay: homeDay }));
            }
        });
        Object.defineProperty(this, "DailyTaskSubtaskPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var params = useParams();
                return (React.createElement(DailyTaskSubtaskPage, { apiClient: _this.domainFactory.apiClient, taskName: params.taskName, subtaskName: params.subtaskName, config: _this.config }));
            }
        });
        Object.defineProperty(this, "ChartTasksPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var params = useParams();
                return (React.createElement(ChartTasks, { apiClient: _this.domainFactory.apiClient, taskName: params.taskName, chartType: params.chartType }));
            }
        });
        Object.defineProperty(this, "CoachingPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a) {
                var me = _a.me, setMe = _a.setMe;
                return (React.createElement(CoachingPage, { accountService: _this.domainFactory.AccountService(), alertPresenter: _this.domainFactory.alertPresenter, apiClient: _this.domainFactory.apiClient, tracker: _this.domainFactory.Tracker(), me: me, setMe: setMe }));
            }
        });
        this.domainFactory = domainFactory;
        this.alertPresenter = alertPresenter;
        this.router = router;
        this.config = config;
    }
    Object.defineProperty(MainContentProvider.prototype, "SearchResultPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(SearchResultPage, { apiClient: this.domainFactory.apiClient, tracker: this.domainFactory.Tracker() });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MainTrainersTopPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.TrainersTopVM) {
                this.TrainersTopVM = new MainTrainersTopPageVM(this.domainFactory.apiClient, this.router);
            }
            return React.createElement(MainTrainersTopPage, { vm: this.TrainersTopVM });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MainInstructorPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            return React.createElement(MainInstructorPage, { apiClient: this.domainFactory.apiClient, instructorID: parseInt(params.id) });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MainCoursePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(CoursePage, { config: this.config, apiClient: this.domainFactory.apiClient, tracker: this.domainFactory.tracker }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MainCourseVolumePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            return (React.createElement(CourseVolumePage, { config: this.config, apiClient: this.domainFactory.apiClient, tracker: this.domainFactory.tracker, volumeID: parseInt(params.id) }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MainChangeCoursePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(ChangeCoursePage, { config: this.config, apiClient: this.domainFactory.apiClient, tracker: this.domainFactory.tracker }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MainCourseRewardPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            return (React.createElement(CourseRewardPage, { courseDayID: parseInt(params.courseDayID), twitterService: this.domainFactory.TwitterService(), config: this.config, apiClient: this.domainFactory.apiClient, tracker: this.domainFactory.tracker, alertPresenter: this.alertPresenter }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "ActivityPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new ActivityPageVM(this.domainFactory.AccountService(), this.domainFactory.Tracker(), this.domainFactory.apiClient, this.router, this.alertPresenter, this.config, this.domainFactory.TwitterService());
            return React.createElement(ActivityPage, { vm: vm });
        }
    });
    /**
     * 検索ページ
     */
    Object.defineProperty(MainContentProvider.prototype, "MainSearchPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(MainSearchPage, { apiClient: this.domainFactory.apiClient, accountService: this.domainFactory.AccountService(), config: this.config, tracker: this.domainFactory.Tracker() }));
        }
    });
    /**
     * ピックアップ商品ページ
     */
    Object.defineProperty(MainContentProvider.prototype, "MainMerchandisePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MainMerchandisePage, { apiClient: this.domainFactory.apiClient });
        }
    });
    /**
     * ランキングページ
     */
    Object.defineProperty(MainContentProvider.prototype, "MainRankingPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MainRankingPage, { apiClient: this.domainFactory.apiClient });
        }
    });
    /**
     * プログラムページ
     */
    Object.defineProperty(MainContentProvider.prototype, "ProgramPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            var vm = new ProgramPageViewModel(params.slug, this.domainFactory.LessonService(), this.domainFactory.SignedCookieService(), this.domainFactory.Tracker(), this.domainFactory.apiClient, this.router, this.domainFactory.TwitterService());
            return React.createElement(ProgramPage, { vm: vm });
        }
    });
    /**
     * レッスンページ
     */
    Object.defineProperty(MainContentProvider.prototype, "LessonPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            var vm = new LessonPageViewModel(params.slug, params.type, this.domainFactory.LessonService(), this.domainFactory.SignedCookieService(), this.router, this.domainFactory.Tracker(), this.domainFactory.apiClient, this.config, this.domainFactory.TwitterService());
            return React.createElement(LessonPage, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "InvitationPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(InvitationPage, { apiClient: this.domainFactory.apiClient, tracker: this.domainFactory.Tracker(), alertPresenter: this.alertPresenter, config: this.config, accountService: this.domainFactory.AccountService(), userInvitationService: this.domainFactory.UserInvitationService() }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "PlanChangePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new PlanChangePageVM(this.domainFactory.AccountService(), this.domainFactory.apiClient, this.domainFactory.Tracker(), this.alertPresenter);
            return React.createElement(PlanChangePage, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "PlanChangeUnsubscribeCpPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new PlanChangeUnsubscribeCpPageVM(this.domainFactory.AccountService(), this.domainFactory.apiClient, this.domainFactory.Tracker(), this.alertPresenter);
            return React.createElement(PlanChangeUnsubscribeCpPage, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "CoachingPlanUnsubscribePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(CoachingPlanUnsubscribePage, { apiClient: this.domainFactory.apiClient, accountService: this.domainFactory.AccountService(), alertPresenter: this.alertPresenter }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "UnsubscribeCautionPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(UnsubscribeCautionPage, { accountService: this.domainFactory.AccountService(), alertPresenter: this.alertPresenter, apiClient: this.domainFactory.apiClient, tracker: this.domainFactory.Tracker(), config: this.config }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "UnsubscribeConfirmPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(UnsubscribeConfirmPage, { accountService: this.domainFactory.AccountService(), alertPresenter: this.alertPresenter }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "NotFoundPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new NotFoundPageVM(this.domainFactory.AccountService());
            return React.createElement(NotFoundPage, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MatchingAssessmentIntroPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MatchingAssessmentIntroPage, { tracker: this.domainFactory.Tracker() });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MatchingAssessmentPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(MatchingAssessmentPage, { config: this.domainFactory.config, apiClient: this.domainFactory.apiClient, tracker: this.domainFactory.Tracker() }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "CheckEmailPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(CheckEmailPage, { accountService: this.domainFactory.AccountService() });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MegalosLiveCalendar", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new MegalosLiveCalendarPageVM(this.domainFactory.AccountService(), this.router, this.alertPresenter);
            return React.createElement(MegalosLiveCalendarPage, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "LiveSchedulePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new LiveSchedulePageVM(this.domainFactory.apiClient, this.domainFactory.Tracker());
            return React.createElement(LiveSchedulePage, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "LivePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            var vm = new LivePageVM(parseInt(params.id), this.domainFactory.AccountService(), this.domainFactory.apiClient, this.domainFactory.Tracker(), this.config);
            return React.createElement(LivePage, { vm: vm, signedCookieService: this.domainFactory.SignedCookieService() });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "FutureUserPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(FutureUserPage, { accountService: this.domainFactory.AccountService() });
        }
    });
    /**
     * オリジナルプレイリストページ
     */
    Object.defineProperty(MainContentProvider.prototype, "OriginalPlaylistPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            var vm = new OriginalPlaylistPageViewModel(params.id, this.domainFactory.Tracker(), this.domainFactory.apiClient, this.router, this.domainFactory.TwitterService());
            return React.createElement(OriginalPlaylistPage, { vm: vm });
        }
    });
    /**
     * お知らせ一覧
     */
    Object.defineProperty(MainContentProvider.prototype, "AnnouncementListPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new MainAnnouncementListVM(this.domainFactory.AnnouncementService(), this.domainFactory.AccountService(), this.domainFactory.apiClient);
            return React.createElement(MainAnnouncementList, { vm: vm });
        }
    });
    /**
     * お知らせ詳細
     */
    Object.defineProperty(MainContentProvider.prototype, "AnnouncementDetailPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            var vm = new MainAnnouncementDetailVM(parseInt(params.id), this.domainFactory.apiClient, this.domainFactory.AccountService());
            return React.createElement(MainAnnouncementDetail, { vm: vm });
        }
    });
    /**
     * プレイリスト作成
     */
    Object.defineProperty(MainContentProvider.prototype, "NewPlaylistPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            var vm = new MainNewPlaylistVM(parseInt(params.id), this.domainFactory.AccountService(), this.domainFactory.apiClient, this.router, this.alertPresenter, this.domainFactory.Tracker());
            return React.createElement(MainNewPlaylist, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "UserSeassonRankHelpPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MainUserSeasonRankHelpLp, { apiClient: this.domainFactory.apiClient, config: this.config });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "InitialGuidePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MainInitialGuide, null);
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "InitialGuideDetailPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MainInitialGuideDetail, null);
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "LiveLessonVimeoPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            var vm = new LiveLessonVimeoVM(parseInt(params.id), this.domainFactory.AccountService(), this.domainFactory.apiClient, this.domainFactory.Tracker());
            return React.createElement(LiveLessonVimeo, { vm: vm, signedCookieService: this.domainFactory.SignedCookieService() });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "DormantPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new DormantPageVM(this.domainFactory.AccountService(), this.alertPresenter, this.domainFactory.Tracker());
            return React.createElement(DormantPage, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "TwitterCallbackPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var vm = new TwitterCallbackViewModel(this.domainFactory.TwitterService());
            return React.createElement(TwitterCallback, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "BillysStudioPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MainBillysStudio, { apiClient: this.domainFactory.apiClient });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MyLessonPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MainMyLesson, { apiClient: this.domainFactory.apiClient, tracker: this.domainFactory.Tracker() });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "ProgramsPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MainPrograms, { apiClient: this.domainFactory.apiClient });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "PlaylistsPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MainPlaylists, { apiClient: this.domainFactory.apiClient });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "PlaylistPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var params = useParams();
            var vm = new PlaylistDetailPageViewModel(Number(params.id), this.domainFactory.LessonService(), this.domainFactory.SignedCookieService(), this.domainFactory.Tracker(), this.domainFactory.apiClient, this.router, this.domainFactory.TwitterService());
            return React.createElement(PlaylistDetail, { vm: vm });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "AndroidAccountDeletionPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement(AndroidAccountDeletion, { accountService: this.domainFactory.AccountService(), tracker: this.domainFactory.Tracker() }));
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "NutrientPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(NutrientPage, { config: this.config, apiClient: this.domainFactory.apiClient });
        }
    });
    Object.defineProperty(MainContentProvider.prototype, "MealScorePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(MealScorePage, { apiClient: this.domainFactory.apiClient });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "SearchResultPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MainTrainersTopPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MainInstructorPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MainCoursePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MainCourseVolumePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MainChangeCoursePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MainCourseRewardPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "ActivityPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MainSearchPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MainMerchandisePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MainRankingPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "ProgramPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "LessonPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "InvitationPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "PlanChangePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "PlanChangeUnsubscribeCpPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "CoachingPlanUnsubscribePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "UnsubscribeCautionPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "UnsubscribeConfirmPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "NotFoundPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MatchingAssessmentIntroPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MatchingAssessmentPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "CheckEmailPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MegalosLiveCalendar", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "LiveSchedulePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "LivePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "FutureUserPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "OriginalPlaylistPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "AnnouncementListPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "AnnouncementDetailPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "NewPlaylistPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "UserSeassonRankHelpPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "InitialGuidePage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "InitialGuideDetailPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "LiveLessonVimeoPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "DormantPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "TwitterCallbackPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "BillysStudioPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MyLessonPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "ProgramsPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "PlaylistsPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "PlaylistPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "AndroidAccountDeletionPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "NutrientPage", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Object)
    ], MainContentProvider.prototype, "MealScorePage", null);
    return MainContentProvider;
}());
export { MainContentProvider };
