var _a;
import * as React from 'react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { LessonType, generateLessonQueryParams, routes } from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite';
import { DailyTasksPageTaskName } from '@lean-body/src/route';
import { dateIsToday, dateMaxToday, getFormattedToFormat, useQuery } from '@lean-body/src/util';
import { TASK_DATE_QUERY, Task, } from '@lean-body/src/domain';
import { TodaysWorkoutsSlider } from '../organisms/todays_workouts_slider';
import { CompletedWorkoutsList } from '../organisms/completed_workouts_list';
import { Colors, MediaBreakPointUp } from '../styles';
import { TaskProgress } from '../organisms/task_progress';
import { PlusIcon } from '../atoms/icons/plus_icon';
import { CustomWorkoutForm } from './custom_workout_form';
import { PageLoading } from './page_loading';
import { EditGoal } from './edit_goal';
import { GrayBoxTabs, TabTypes } from '../molecules/gray_box_tabs';
import { ActivityTabs, LifetimeActivityType } from './lifetime_activity_chart';
import { DatePagination, DatePaginationStep } from '../molecules/date_pagination';
import { LifetimeTaskActivity } from './lifetime_task_activity';
import { ArrowDirection, ArrowIconWithMargin } from '../atoms';
export var customWorkoutPageTypes = {
    list: 'list',
    new: 'new',
    edit: 'edit',
    goalEdit: 'goal_edit',
};
export var WorkoutTask = function (_a) {
    var apiClient = _a.apiClient, containerClassObject = _a.containerClassObject, headerClassObject = _a.headerClassObject, arrowIconClassObject = _a.arrowIconClassObject, onClickBackHome = _a.onClickBackHome;
    var navigate = useNavigate();
    var today = new Date();
    var query = useQuery();
    var queryDate = dayjs(query.get(TASK_DATE_QUERY) || today).toDate();
    var _b = useState(queryDate), date = _b[0], setDate = _b[1];
    var _c = useState(customWorkoutPageTypes.list), pageType = _c[0], setPageType = _c[1];
    var _d = useState(null), selectedUserCustomWorkout = _d[0], setSelectedUserCustomWorkout = _d[1];
    var _e = useState(null), dailyWorkoutTask = _e[0], setDailyWorkoutTask = _e[1];
    var _f = useState(), value = _f[0], setValue = _f[1];
    var _g = useState(), workoutGoals = _g[0], setWorkoutGoals = _g[1];
    var _h = React.useState(ActivityTabs.daily), tab = _h[0], setTab = _h[1];
    var _j = useState(DatePaginationStep.day), datePaginationStep = _j[0], setDatePaginationStep = _j[1];
    useEffect(function () {
        if (pageType === customWorkoutPageTypes.list) {
            apiClient.fetchDailyWorkoutTask(getFormattedToFormat(date)).then(function (v) {
                setDailyWorkoutTask(v);
            });
        }
        else if (pageType === customWorkoutPageTypes.goalEdit) {
            apiClient.fetchWorkoutGoals().then(function (workoutGoals) {
                var _a;
                setValue((_a = workoutGoals.find(function (v) { return v.name === Task.roundTaskCalories(dailyWorkoutTask.workout.goal).toString(); })) === null || _a === void 0 ? void 0 : _a.value);
                setWorkoutGoals(workoutGoals);
            });
        }
    }, [pageType, date]);
    useEffect(function () {
        onChangeTab(tab);
    }, [tab]);
    var onClickDatePagination = function (value) {
        setDate(dateMaxToday(value));
    };
    var onChangeTab = function (key) {
        switch (key) {
            case ActivityTabs.weekly:
                setDatePaginationStep(DatePaginationStep.week);
                break;
            case ActivityTabs.monthly:
                setDatePaginationStep(DatePaginationStep.month);
                break;
            case ActivityTabs.quarterly:
                setDatePaginationStep(DatePaginationStep.quarter);
                break;
            default:
                setDatePaginationStep(DatePaginationStep.day);
        }
    };
    var onClickCourseLesson = function (courseDaysLesson) {
        var url = routes.LESSON.compile({ type: LessonType.normal, slug: courseDaysLesson.lesson.slug });
        var params = generateLessonQueryParams(courseDaysLesson.id);
        navigate("".concat(url).concat(params));
    };
    var onClickEditWorkoutGoal = function () {
        setPageType(customWorkoutPageTypes.goalEdit);
    };
    var onSaveWorkoutGoal = function (v) {
        apiClient.saveWorkoutGoal(Number(v)).then(function () {
            setPageType(customWorkoutPageTypes.list);
            setValue(v);
        });
    };
    var onClickAddCompletedCustomWorkout = function () {
        setPageType(customWorkoutPageTypes.new);
    };
    var onSaveCustomWorkout = function () {
        setPageType(customWorkoutPageTypes.list);
    };
    var onClickCompletedCustomWorkout = function (userCustomWorkout) {
        setSelectedUserCustomWorkout(userCustomWorkout);
        setPageType(customWorkoutPageTypes.edit);
    };
    var onClickBack = function () {
        setPageType(customWorkoutPageTypes.list);
        setSelectedUserCustomWorkout(null);
    };
    var onClickCompletedLessonWorkout = function (taskLesson) {
        if (taskLesson.lesson) {
            navigate(routes.LESSON.compile({ type: LessonType.normal, slug: taskLesson.lesson.slug }));
        }
        else if (taskLesson.liveLesson) {
            navigate(routes.LIVE_LESSON.compile({ id: taskLesson.liveLesson.id.toString() }));
        }
    };
    var onClickCompletedStepsWorkout = function () {
        navigate("".concat(routes.DAILY_TASKS.compile({
            taskName: DailyTasksPageTaskName.steps,
        }), "?").concat(TASK_DATE_QUERY, "=").concat(getFormattedToFormat(date)));
    };
    var isLoading = function () {
        if (pageType === customWorkoutPageTypes.goalEdit) {
            return !workoutGoals;
        }
        else {
            return !dailyWorkoutTask;
        }
    };
    if (isLoading()) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement(React.Fragment, null, pageType === customWorkoutPageTypes.list ? (React.createElement("div", { className: css(containerClassObject) },
        React.createElement(ArrowIconWithMargin, { classObject: arrowIconClassObject, direction: ArrowDirection.left, color: Colors.TextDark, onClick: function () {
                onClickBackHome(queryDate);
            } }),
        React.createElement("div", { className: css(headerClassObject) }, "\u30EF\u30FC\u30AF\u30A2\u30A6\u30C8"),
        React.createElement(GrayBoxTabs, { setTab: setTab, tab: tab, tabType: TabTypes.activity, classObject: styles.tab }),
        React.createElement(DatePagination, { value: date, max: today, step: datePaginationStep, onClickNext: onClickDatePagination, onClickPrev: onClickDatePagination, classObject: styles.datePagination }),
        tab === ActivityTabs.daily ? (React.createElement(React.Fragment, null,
            React.createElement(TaskProgress, { current: Task.roundTaskCalories(dailyWorkoutTask.workout.current), goal: Task.roundTaskCalories(dailyWorkoutTask.workout.goal), unitLabel: "kcal", classObject: styles.progressContainerWorkout, showEditButton: dateIsToday(date), onClickButton: onClickEditWorkoutGoal }),
            dailyWorkoutTask.courseDaysLessons.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.sectionLabel) }, "\u4ECA\u65E5\u306E\u30EF\u30FC\u30AF\u30A2\u30A6\u30C8"),
                React.createElement(TodaysWorkoutsSlider, { classObject: styles.todaysWorkouts, courseDaysLessons: dailyWorkoutTask.courseDaysLessons, onClickLesson: onClickCourseLesson }))),
            React.createElement("div", { className: css(styles.sectionDivider) }),
            React.createElement("div", { className: css(styles.addCustomWorkoutContainer), onClick: onClickAddCompletedCustomWorkout },
                React.createElement("div", { className: css(styles.addCustomWorkoutLabel) }, "\u30AB\u30B9\u30BF\u30E0\u30EF\u30FC\u30AF\u30A2\u30A6\u30C8\u3092\u8FFD\u52A0"),
                React.createElement(PlusIcon, { color: Colors.Black })),
            React.createElement("div", { className: css(styles.sectionLabel) }, "\u5B8C\u4E86\u3057\u305F\u30EF\u30FC\u30AF\u30A2\u30A6\u30C8"),
            React.createElement(CompletedWorkoutsList, { userCustomWorkouts: dailyWorkoutTask.userCustomWorkouts, taskLessons: dailyWorkoutTask.completedLessons, stepActivity: dailyWorkoutTask.stepActivity, onClickCustomWorkout: onClickCompletedCustomWorkout, onClickLessonWorkout: onClickCompletedLessonWorkout, onClickStepsWorkout: onClickCompletedStepsWorkout, classObject: styles.completedWorkouts }))) : (React.createElement(LifetimeTaskActivity, { activityType: LifetimeActivityType.workoutCalorie, apiClient: apiClient, tab: tab, goalDate: date, classObject: styles.lifetimeWorkoutActivity })))) : pageType === customWorkoutPageTypes.goalEdit ? (React.createElement(EditGoal, { title: "1\u65E5\u306E\u76EE\u6A19\u6D88\u8CBB\u30AB\u30ED\u30EA\u30FC", unit: "kcal", defaultValue: value, options: workoutGoals, onClickBack: onClickBack, onSave: onSaveWorkoutGoal })) : pageType === customWorkoutPageTypes.new ? (React.createElement(CustomWorkoutForm, { apiClient: apiClient, date: date, onClickBack: onClickBack, onSave: onSaveCustomWorkout })) : (pageType === customWorkoutPageTypes.edit && (React.createElement(CustomWorkoutForm, { apiClient: apiClient, date: date, onClickBack: onClickBack, onSave: onSaveCustomWorkout, userCustomWorkout: selectedUserCustomWorkout })))));
};
var styles = StyleSheet.create({
    tab: {
        marginBottom: 20,
    },
    datePagination: {
        marginBottom: 14,
    },
    progressContainerWorkout: {
        margin: '24px 0',
    },
    sectionLabel: {
        width: '100%',
        padding: '24px 0 8px 0',
        color: Colors.TextDark,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    sectionDivider: {
        display: 'flex',
        width: '100%',
        height: 32,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        ':after': (_a = {
                content: '""',
                position: 'absolute',
                left: 0,
                width: '100%',
                height: 8,
                backgroundColor: Colors.CyanLightest
            },
            _a[MediaBreakPointUp.MD] = {
                position: 'static',
            },
            _a),
    },
    addCustomWorkoutContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 60,
        marginTop: 24,
        padding: 20,
        gap: 20,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 10,
        cursor: 'pointer',
    },
    addCustomWorkoutLabel: {
        fontSize: 14,
        fontWeight: 500,
        height: 16,
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
    todaysWorkouts: {
        width: '100%',
    },
    completedWorkouts: {
        width: '100%',
    },
    lifetimeWorkoutActivity: {
        width: 327,
        margin: '0 auto',
    },
});
