var ChatbotChoice = /** @class */ (function () {
    function ChatbotChoice(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sectionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "text", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "withInputText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextSectionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.sectionID = data.sectionId;
        this.text = data.text;
        this.withInputText = data.withInputText;
        this.nextSectionID = data.nextSectionId;
        this.orderIndex = data.orderIndex;
    }
    return ChatbotChoice;
}());
export { ChatbotChoice };
