import * as dayjs from 'dayjs';
import { floorOver2DecimalPoint, floorOverDigits, replaceHalfWidthNumber } from '../util';
export var MAX_BODY_FAT_DIGITS = 2;
var UserBodyFat = /** @class */ (function () {
    function UserBodyFat(v) {
        Object.defineProperty(this, "bodyFat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "targetDateAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.bodyFat = v.bodyFat;
        this.targetDateAt = dayjs.unix(v.targetDateAt);
    }
    Object.defineProperty(UserBodyFat, "formatBodyFat", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (bodyFat) {
            var value = replaceHalfWidthNumber(bodyFat);
            value = floorOver2DecimalPoint(value);
            return floorOverDigits(value, MAX_BODY_FAT_DIGITS);
        }
    });
    return UserBodyFat;
}());
export { UserBodyFat };
