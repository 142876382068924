import { isiOSApp } from '@lean-body/src/util';
import axios from 'axios';
import { ASPServerError } from '../errors';
/**
 * 埋め込みタグ群を挿入するクラス
 * 埋め込みだけが目的のタグをここに集約させています。
 * GoogleAnalyticsなど、埋め込んだ後に何か処理をする必要があるものは別なクラスにしています。
 */
var TagClient = /** @class */ (function () {
    function TagClient(config, userID) {
        var _this = this;
        Object.defineProperty(this, "axios", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.config = config;
        this.userId = userID;
        this.axios = axios.create();
        this.embedGlobalTags();
        this.axios.interceptors.response.use(function (res) {
            // 何もしない
            return res;
        }, function (error) {
            _this.onError(error);
        });
    }
    /*
     * グローバルタグ
     */
    Object.defineProperty(TagClient.prototype, "embedGlobalTags", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            // iOS(webview)でgtmの読み込みエラーが発生すると表示が遅くなったりするバグがあるので対象から外す
            // localhostはエラーになるので外す
            if (isiOSApp() || !this.config.isProdOrStg)
                return;
            this.embedGoogleTagManager();
            this.embedGoogleOptimize();
        }
    });
    /*
     * コンバージョンページで読み込まれる
     */
    Object.defineProperty(TagClient.prototype, "embedConversionTags", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId) {
            this.userId = userId;
            this.embedGoogleAdConversionTag();
            this.embedGoogleCodeConversionTag();
            this.embedFactBase();
        }
    });
    // GoogleCodeのタグ
    Object.defineProperty(TagClient.prototype, "embedGoogleCodeConversionTag", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var cdataScript = document.createElement('script');
            cdataScript.innerHTML = "\n        /* <![CDATA[ */\n        var google_conversion_id = 822881874;\n        var google_conversion_label = \"PJi6CK_7mYMBENLcsIgD\";\n        var google_remarketing_only = false;\n        /* ]]> */";
            var cvScript = document.createElement('script');
            cvScript.src = '//www.googleadservices.com/pagead/conversion.js';
            var noScript = document.createElement('noscript');
            noScript.innerHTML = "<div style=\"display:inline;\">\n            <img height=\"1\" width=\"1\" style=\"border-style:none;\" alt=\"\" src=\"//www.googleadservices.com/pagead/conversion/822881874/?label=PJi6CK_7mYMBENLcsIgD&amp;guid=ON&amp;script=0\"/>\n        </div>";
            document.body.appendChild(cdataScript);
            document.body.appendChild(cvScript);
            document.body.appendChild(noScript);
        }
    });
    // FactBaseのGlobalSiteTag(Google関連のタグ)
    Object.defineProperty(TagClient.prototype, "embedFactBase", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            try {
                var s1 = document.createElement('script');
                s1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-728092191';
                var s2 = document.createElement('script');
                s2.innerHTML = "\n          window.dataLayer = window.dataLayer || [];\n          function gtag(){dataLayer.push(arguments);}\n          gtag(\u2018js\u2019, new Date());\n          gtag(\u2018config\u2019, \u2018AW-728092191\u2019);\n          ";
                var s3 = document.createElement('script');
                s3.innerHTML = "\n          gtag(\u2018event\u2019, \u2018conversion\u2019, {\n          \u2018send_to\u2019: \u2018AW-728092191/Gcs1COOzlqcBEJ-cl9sC\u2019,\n          \u2018transaction_id\u2019: \u2018\u2019\n          });";
                document.head.appendChild(s1);
                document.head.appendChild(s2);
                document.head.appendChild(s3);
            }
            catch (e) {
                console.log(e); // 分析用なのでエラーはハンドリングしない
            }
        }
    });
    // Googleタグマネージャ
    Object.defineProperty(TagClient.prototype, "embedGoogleTagManager", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var ad = document.createElement('div');
            ad.id = 'google_tag_manager';
            ad.innerHTML = "<!-- Global site tag (gtag.js) - Google Ads: 822881874 --> <script async src=\u201Chttps://www.googletagmanager.com/gtag/js?id=AW-822881874\u201C></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\u2018js\u2019, new Date()); gtag(\u2018config\u2019, \u2018AW-822881874\u2019); </script>";
            var gtmHead = document.createElement('script');
            gtmHead.innerHTML = "\n        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n        })(window,document,'script','dataLayer','".concat(this.config.gtmId, "');\n        ");
            var gtmBody = document.createElement('noscript');
            gtmBody.innerHTML = "\n        <noscript><iframe src=\"https://www.googletagmanager.com/ns.html?id=".concat(this.config.gtmId, "\"\n        height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe></noscript>\n        ");
            document.getElementsByTagName('head')[0].appendChild(ad);
            document.getElementsByTagName('head')[0].appendChild(gtmHead);
            document.getElementsByTagName('head')[0].appendChild(gtmBody);
        }
    });
    // google広告コンバージョンタグ
    Object.defineProperty(TagClient.prototype, "embedGoogleAdConversionTag", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var gTag = document.createElement('div');
            gTag.id = 'google_ad_conversion';
            gTag.innerHTML = "\n        <script>\n            gtag('event', 'conversion', {'send_to': 'AW-822881874/PJi6CK_7mYMBENLcsIgD'});\n        </script>\n        ";
            document.getElementsByTagName('head')[0].appendChild(gTag);
        }
    });
    // GoogleOptimyieグローバルタグ(あえてpublic)
    Object.defineProperty(TagClient.prototype, "embedGoogleOptimize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            // <!-- Global site tag (gtag.js) - Google Analytics -->
            // FIXME: マーケチームがGA4に完全に切り替えるまで古い方は残して欲しいとのこと、移行したら削除する
            var gTagLinkOld = document.createElement('script');
            gTagLinkOld.src = "https://www.googletagmanager.com/gtag/js?id=UA-113696457-3";
            gTagLinkOld.async = true;
            var gTagLink = document.createElement('script');
            gTagLink.src = "https://www.googletagmanager.com/gtag/js?id=".concat(this.config.gaId);
            gTagLink.async = true;
            var gTagConfigOld = document.createElement('script');
            gTagConfigOld.innerHTML = "\n            window.dataLayer = window.dataLayer || [];\n            function gtag(){dataLayer.push(arguments);}\n            gtag('js', new Date());\n            gtag('config', 'UA-113696457-3');\n        ";
            var gTagConfig = document.createElement('script');
            gTagConfig.innerHTML = "\n            window.dataLayer = window.dataLayer || [];\n            function gtag(){dataLayer.push(arguments);}\n            gtag('js', new Date());\n            gtag('config', '".concat(this.config.gaId, "', {\n              send_page_view: false\n            });\n        ");
            // GoogleOptimizeのタグ
            ga('require', 'GTM-KSG5WS9');
            // ページ非表示スニペット
            var googleOptimizeSnippetsStyle = document.createElement('style');
            googleOptimizeSnippetsStyle.innerHTML = ".async-hide { opacity: 0 !important}";
            var googleOptimizeSnippetsJS = document.createElement('script');
            googleOptimizeSnippetsJS.innerHTML = "\n            (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;\n            h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};\n            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;\n            })(window,document.documentElement,'async-hide','dataLayer',4000,\n            {'GTM-KSG5WS9':true});\n        ";
            document.head.appendChild(gTagLink);
            document.head.appendChild(gTagLinkOld);
            document.head.appendChild(gTagConfig);
            document.head.appendChild(gTagConfigOld);
            document.head.appendChild(googleOptimizeSnippetsStyle);
            document.head.appendChild(googleOptimizeSnippetsJS);
        }
    });
    Object.defineProperty(TagClient.prototype, "trackView", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.gtag &&
                window.gtag('config', this.config.gaId, {
                    page_title: window.location.pathname,
                    page_location: window.location.href,
                    user_id: this.userId,
                });
        }
    });
    Object.defineProperty(TagClient.prototype, "trackClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (category, label, value) {
            window.gtag &&
                window.gtag('event', 'click', {
                    page_title: window.location.pathname,
                    page_location: window.location.href,
                    event_category: category,
                    event_label: label,
                    value: value,
                    user_id: this.userId,
                });
        }
    });
    Object.defineProperty(TagClient.prototype, "trackSlide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (category, label, value) {
            window.gtag &&
                window.gtag('event', 'slide', {
                    page_title: window.location.pathname,
                    page_location: window.location.href,
                    event_category: category,
                    event_label: label,
                    value: value,
                    user_id: this.userId,
                });
        }
    });
    // 関電のCVタグのパラメータにLeanBodyのユーザーIDを設定する
    Object.defineProperty(TagClient.prototype, "embedKandenGTMId", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId) {
            var kandenGTMId = document.createElement('script');
            kandenGTMId.innerHTML = "\n            var kanden_uid = \"".concat(userId.toString(), "\";\n        ");
            document.getElementsByTagName('head')[0].appendChild(kandenGTMId);
        }
    });
    // Sign in with Apple JS のスクリプトを埋め込み
    Object.defineProperty(TagClient.prototype, "embedAppleScript", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var script = document.createElement('script');
            script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/ja_JP/appleid.auth.js';
            script.async = true;
            var onScriptError = function () {
                console.log('onScriptError');
            };
            script.addEventListener('error', onScriptError);
            document.body.appendChild(script);
        }
    });
    Object.defineProperty(TagClient.prototype, "afterCCRegistration", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            this.embedConversionTags(id);
            // 関電のCVタグのパラメータにLeanBodyのユーザーIDを設定する
            this.embedKandenGTMId(id);
        }
    });
    Object.defineProperty(TagClient.prototype, "onError", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (error) {
            var e = error;
            if (!e.response) {
                throw e;
            }
            var data = e.response.data;
            if (data.displayMessage) {
                throw new ASPServerError(e.message, data.displayMessage);
            }
            throw error;
        }
    });
    return TagClient;
}());
export { TagClient };
