import * as dayjs from 'dayjs';
import { floorOver2DecimalPoint, floorOverDigits, replaceHalfWidthNumber } from '../util';
export var MAX_WEIGHT_DIGITS = 3;
var UserWeight = /** @class */ (function () {
    function UserWeight(v) {
        Object.defineProperty(this, "weight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "targetDateAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.weight = v.weight;
        this.targetDateAt = dayjs.unix(v.targetDateAt);
    }
    Object.defineProperty(UserWeight, "formatWeight", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (weight) {
            var value = replaceHalfWidthNumber(weight);
            value = floorOver2DecimalPoint(value);
            return floorOverDigits(value, MAX_WEIGHT_DIGITS);
        }
    });
    return UserWeight;
}());
export { UserWeight };
var WeightAndBodyFatActivities = /** @class */ (function () {
    function WeightAndBodyFatActivities(v) {
        Object.defineProperty(this, "weightAndBodyFatActivityList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weightGraphScales", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bodyFatGraphScales", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.weightAndBodyFatActivityList = v.weightAndBodyFatActivityList.map(function (v) { return new WeightAndBodyFatActivity(v); });
        this.weightGraphScales = v.weightGraphScales;
        this.bodyFatGraphScales = v.bodyFatGraphScales;
    }
    return WeightAndBodyFatActivities;
}());
export { WeightAndBodyFatActivities };
var WeightAndBodyFatActivity = /** @class */ (function () {
    function WeightAndBodyFatActivity(v) {
        Object.defineProperty(this, "weight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bodyFat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.weight = v.weight;
        this.bodyFat = v.bodyFat;
        this.dateAt = dayjs.unix(v.dateAt);
    }
    return WeightAndBodyFatActivity;
}());
export { WeightAndBodyFatActivity };
var TargetWeightAndBodyFat = /** @class */ (function () {
    function TargetWeightAndBodyFat(v) {
        Object.defineProperty(this, "weight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weightDateAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bodyFat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bodyFatDateAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.weight = v.weight;
        this.weightDateAt = dayjs.unix(v.weightDateAt);
        this.bodyFat = v.bodyFat;
        this.bodyFatDateAt = dayjs.unix(v.bodyFatDateAt);
    }
    Object.defineProperty(TargetWeightAndBodyFat, "formatWeight", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (weight) {
            var value = replaceHalfWidthNumber(weight);
            value = floorOver2DecimalPoint(value);
            return floorOverDigits(value, MAX_WEIGHT_DIGITS);
        }
    });
    return TargetWeightAndBodyFat;
}());
export { TargetWeightAndBodyFat };
