var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import DOMPurify from 'dompurify';
import { getFormattedToSlashFormat } from '@lean-body/src/util';
import { makeObservable, observable } from 'mobx';
var AnnouncementService = /** @class */ (function () {
    function AnnouncementService(apiClient, config) {
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unreadAnnouncementCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.apiClient = apiClient;
        this.config = config;
    }
    Object.defineProperty(AnnouncementService.prototype, "getUnreadAnnounecmentCount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!this.unreadAnnouncementCount) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.apiClient.getUnreadAnnouncementCount()];
                        case 1:
                            res = _a.sent();
                            this.unreadAnnouncementCount = new UnreadAnnouncementCount(res);
                            _a.label = 2;
                        case 2: return [2 /*return*/, this.unreadAnnouncementCount];
                    }
                });
            });
        }
    });
    return AnnouncementService;
}());
export { AnnouncementService };
var Announcements = /** @class */ (function () {
    function Announcements(announcementsData, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "announcements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lastPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.announcements = announcementsData.announcements.filter(function (v) { return v; }).map(function (v) { return new Announcement(v, config); });
        this.lastPage = announcementsData.lastPage;
        this.config = config;
    }
    return Announcements;
}());
export { Announcements };
var Announcement = /** @class */ (function () {
    function Announcement(announcementData, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "body", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publishAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "formattedPublishAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thumbnailSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRead", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = announcementData.id;
        this.title = announcementData.title;
        this.body = this.parseMarkdown(announcementData.body);
        this.formattedPublishAt = announcementData.publishAt && getFormattedToSlashFormat(announcementData.publishAt);
        this.thumbnailSrc = config.cdnBaseURL + announcementData.thumbnailSrc;
        this.isRead = announcementData.isRead;
        this.config = config;
    }
    Object.defineProperty(Announcement.prototype, "isNew", {
        get: function () {
            return !this.isRead;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Announcement.prototype, "parseMarkdown", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (body) {
            // h2タグとaタグの末尾の改行を取り除く
            body = body.replace(/(<h2.*\/h2>)(\r\n)/g, '$1');
            body = body.replace(/(<a.*\/a>)(\r\n)/g, '$1');
            DOMPurify.addHook('afterSanitizeAttributes', function (node) {
                if (node.tagName && node.tagName.toLowerCase() === 'iframe') {
                    var src = node.getAttribute('src');
                    if (src && !src.includes('www.youtube.com')) {
                        node.removeAttribute('src');
                    }
                }
            });
            return DOMPurify.sanitize(body, {
                ADD_TAGS: ['iframe'],
                ADD_ATTR: ['width', 'height', 'src', 'title', 'frameborder', 'allow', 'referrerpolicy', 'allowfullscreen'],
            });
        }
    });
    return Announcement;
}());
export { Announcement };
var UnreadAnnouncementCount = /** @class */ (function () {
    function UnreadAnnouncementCount(unreadAnnouncementCountData) {
        Object.defineProperty(this, "count", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            count: observable,
        });
        this.count = unreadAnnouncementCountData.unreadAnnouncementCount;
    }
    Object.defineProperty(UnreadAnnouncementCount.prototype, "moreThanZero", {
        get: function () {
            return this.count > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnreadAnnouncementCount.prototype, "substructCount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.moreThanZero)
                this.count--;
        }
    });
    Object.defineProperty(UnreadAnnouncementCount.prototype, "changeToZero", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.count = 0;
        }
    });
    return UnreadAnnouncementCount;
}());
export { UnreadAnnouncementCount };
