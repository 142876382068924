var _a, _b;
import * as React from 'react';
import { routes } from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite';
import { FIXED_FOOTER_HEIGHT, PageLoading, UnsubscribeWarning } from '@lean-body/components/organisms';
import { Colors, MediaBreakPointUp, Sizes } from '@lean-body/components/styles';
import { SettingItemKeys } from '../organisms/setting_menu';
import { ContractPlan } from '../atoms/contract_plan';
import { useNavigate } from 'react-router';
import { UnsubscribeChatbot } from '../organisms/unsubscribe_chatbot';
import { useNowUnixtime } from '@lean-body/src/util';
// TODO: チャットbot切り替え後に削除
var PLAN_NAME_ID = 'smashLeanBodyPlanNameId'; // 退会チャットボット（株式会社Smash）で使用しているので変更した際は、連絡する
export var CHATBOT_SWITCH_DATE = 1737385200; // 2025-01-21 00:00:00
export var UnsubscribeCautionPage = function (_a) {
    var accountService = _a.accountService, alertPresenter = _a.alertPresenter, apiClient = _a.apiClient, tracker = _a.tracker, config = _a.config;
    var isNewChatbot = useNowUnixtime() > CHATBOT_SWITCH_DATE;
    var navigate = useNavigate();
    var _b = React.useState(), me = _b[0], setMe = _b[1];
    React.useEffect(function () {
        accountService.fetchMe().then(function (v) {
            setMe(v);
            if (v.unsubscribeScheduledAt) {
                navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
                alertPresenter.showMessage('退会予約ずみです');
            }
            // 休眠中は退会できないようにリダイレクト
            if (v.isDormant) {
                navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
                alertPresenter.showMessage('休眠中は退会できません');
            }
        });
    }, []);
    if (!me)
        return React.createElement(PageLoading, null);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.head) }, "\u9000\u4F1A\u624B\u7D9A\u304D"),
        React.createElement("div", { className: css(styles.content) },
            React.createElement(ContractPlan, { name: me.payment.currentPlan.name, priceWithTax: me.payment.currentPlan.amount.toLocaleString(), updateDate: me.payment.curPeriodEndDate, id: isNewChatbot ? '' : PLAN_NAME_ID }),
            React.createElement(UnsubscribeWarning, { payment: me.payment }),
            isNewChatbot && React.createElement(UnsubscribeChatbot, { apiClient: apiClient, tracker: tracker, config: config }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            backgroundColor: Colors.White,
            minHeight: "calc(100vh - ".concat(FIXED_FOOTER_HEIGHT, "px - ").concat(Sizes.MobileHeaderHeight, "px)"),
            padding: '0 30px'
        },
        _a[MediaBreakPointUp.MD] = {
            minHeight: '100vh',
        },
        _a),
    head: {
        fontSize: 18,
        fontWeight: 'bold',
        padding: '40px 0 24px',
        textAlign: 'start',
        marginBottom: 30,
    },
    content: (_b = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 40
        },
        _b[MediaBreakPointUp.MD] = {
            padding: '0 50px',
        },
        _b),
});
