var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useEffect, useState, useRef } from 'react';
import { routes } from '@lean-body/src/route';
import { Colors, MediaBreakPointUp } from '../styles';
import { Button, ButtonType, CloseIcon } from '../atoms';
import { ChatbotContent } from '@lean-body/src/domain/chatbot_section';
import { UnsubscribeChatbotRow } from '../organisms/unsubscribe_chatbot_row';
import { UnsubscribeChatbotTextInput } from '../molecules/unsubscribe_chatbot_text_input';
import { FIXED_FOOTER_HEIGHT } from './fixed_footer';
import { ZIndex } from '../constants';
import { randomString } from '@lean-body/src/util';
export var UnsubscribeChatbot = function (_a) {
    var apiClient = _a.apiClient, tracker = _a.tracker, config = _a.config;
    var navigate = useNavigate();
    var scrollBottomRef = useRef(null);
    var _b = useState(null), chatbotSessionId = _b[0], setChatbotSessionId = _b[1];
    var _c = useState(false), isShowChatbot = _c[0], setIsShowChatbot = _c[1];
    var _d = useState(null), currentSection = _d[0], setCurrentSection = _d[1];
    var _e = useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState([]), displayedChatbotList = _f[0], setDisplayedChatbotList = _f[1];
    var _g = useState([]), answeredIDs = _g[0], setAnsweredIDs = _g[1];
    var _h = useState(false), isDisplayTextInput = _h[0], setIsDisplayTextInput = _h[1];
    var _j = useState(null), selectedChoice = _j[0], setSelectedChoice = _j[1];
    var _k = useState(''), textValue = _k[0], setTextValue = _k[1];
    useEffect(function () {
        if (currentSection) {
            var loadingContents_1 = [];
            // 表示する内容（descriptions と choices）を loadingContents にまとめる
            currentSection.descriptions.forEach(function (v) {
                loadingContents_1.push(new ChatbotContent(config, currentSection.id, v.id, false, v.iconUrl, v.isUnsubscribeButton, v.text, v.imageUrl, v.imageLinkUrl, v.nextSectionID, v.linkUrl, null));
            });
            if (currentSection.choices.length > 0) {
                loadingContents_1.push(new ChatbotContent(config, currentSection.id, null, false, currentSection.choiceIconUrl, false, null, null, null, null, null, currentSection.choices));
            }
            var delayTime_1 = 2000;
            var currentSectionContent_1 = [];
            loadingContents_1.forEach(function (v, i) {
                setTimeout(function () {
                    // 選択肢の場合はローディングを入れずに表示する
                    if (v.choices === null) {
                        setIsLoading(true);
                    }
                    currentSectionContent_1.push(v);
                    setDisplayedChatbotList(__spreadArray(__spreadArray([], displayedChatbotList, true), currentSectionContent_1, true));
                }, i * delayTime_1);
                if (v.choices === null) {
                    setTimeout(function () {
                        setIsLoading(false);
                        if (v.nextSectionID)
                            fetchSection(v.nextSectionID);
                    }, (i + 1) * delayTime_1);
                    if (v.linkUrl && !v.isUnsubscribeButton) {
                        setTimeout(function () { return navigate(v.linkUrl); }, (i + 2) * delayTime_1);
                    }
                }
                var isLastContent = loadingContents_1.length === i + 1;
                if (isLastContent && currentSection.isEnd) {
                    // 最後のチャット表示の5秒後にチャットを閉じる
                    setTimeout(function () { return setIsShowChatbot(false); }, (i + 3.5) * delayTime_1);
                }
            });
        }
    }, [currentSection]);
    // ロード中表示とチャット表示時にスクロールを1番下に下げる
    useEffect(function () {
        scrollBottom(50);
    }, [isLoading, displayedChatbotList]);
    var fetchSection = function (sectionID) {
        apiClient.fetchUnsubscribeChatbotSection(sectionID).then(function (section) { return setCurrentSection(section); });
    };
    // Chatbot フロートボタンをクリック（チャット開始）
    var onClickButton = function () {
        setIsShowChatbot(true);
        if (displayedChatbotList.length === 0) {
            fetchSection(null);
            var newChatbotSessionId = randomString(24);
            setChatbotSessionId(newChatbotSessionId);
            tracker.trackUnsubscribeChatbot(newChatbotSessionId, null, null, null, null, null, true, false);
        }
        else {
            scrollBottom(100);
        }
    };
    // 選択肢をクリック
    var onClickChoice = function (choice, index) {
        // 既に選択済みの回答の場合は何も行わない
        answeredIDs.map(function (v) {
            if (v.choiceID == choice.id && index == v.choiceIndex)
                return;
        });
        // 既に回答済みの設問の場合は回答内容を変更する
        answeredIDs.map(function (v) {
            if (v.sectionID == choice.sectionID && index == v.choiceIndex)
                v.choiceID = choice.id;
            return v;
        });
        setAnsweredIDs(answeredIDs);
        // 選択した内容を保持しつつ回答として表示
        setAnsweredIDs(__spreadArray(__spreadArray([], answeredIDs, true), [{ choiceIndex: index, sectionID: choice.sectionID, choiceID: choice.id }], false));
        var chatbotContent = new ChatbotContent(config, choice.sectionID, null, true, null, false, choice.text, null, null, null, null, null);
        setDisplayedChatbotList(__spreadArray(__spreadArray([], displayedChatbotList, true), [chatbotContent], false));
        tracker.trackUnsubscribeChatbot(chatbotSessionId, choice.sectionID, null, choice.id, null, choice.nextSectionID, false, false);
        if (choice.withInputText) {
            setIsDisplayTextInput(true);
            // テキスト入力後に次の設問に移れるように choice を保持しておく
            setSelectedChoice(choice);
        }
        else {
            fetchSection(choice.nextSectionID);
        }
    };
    var onClickClose = function () {
        setIsShowChatbot(false);
    };
    var onClickSendText = function () {
        if (!textValue)
            return;
        setIsDisplayTextInput(false);
        var chatbotContent = new ChatbotContent(config, null, null, true, null, false, textValue, null, null, null, null, null);
        setDisplayedChatbotList(__spreadArray(__spreadArray([], displayedChatbotList, true), [chatbotContent], false));
        fetchSection(selectedChoice.nextSectionID);
        tracker.trackUnsubscribeChatbot(chatbotSessionId, selectedChoice.sectionID, null, selectedChoice.id, textValue, selectedChoice.nextSectionID, false, false);
        setTextValue('');
        setSelectedChoice(null);
    };
    var onClickUnsubscribeButton = function (descriptionId) {
        tracker.trackUnsubscribeChatbot(chatbotSessionId, null, descriptionId, null, null, null, false, true);
        navigate(routes.UNSUBSCRIBE_CONFIRM.compile());
    };
    var onClickLink = function (sectionId, descriptionId) {
        tracker.trackUnsubscribeChatbot(chatbotSessionId, sectionId, descriptionId, null, null, null, false, true);
    };
    var scrollBottom = function (ms) {
        // 画像の場合に読み込み前にスクロールしてしまうので遅延を入れれるように setTimeout を使用
        setTimeout(function () {
            var _a;
            (_a = scrollBottomRef === null || scrollBottomRef === void 0 ? void 0 : scrollBottomRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'auto' });
        }, ms);
    };
    return (React.createElement(React.Fragment, null, isShowChatbot ? (React.createElement("div", { className: css(styles.chatContainer) },
        React.createElement("div", { className: css(styles.chatHeader) },
            React.createElement("div", { className: css(styles.chatHeaderText) }, "\u3042\u306A\u305F\u306E\u6C17\u6301\u3061\u3092\u304A\u805E\u304B\u305B\u304F\u3060\u3055\u3044"),
            React.createElement(CloseIcon, { color: Colors.White, size: 16, containerClassObject: styles.closeIcon, onClick: onClickClose })),
        React.createElement("div", { className: css(styles.chatBody, isDisplayTextInput && styles.chatBodyWithInput) },
            displayedChatbotList.map(function (v, i) { return (React.createElement("div", { key: i },
                React.createElement(UnsubscribeChatbotRow, { index: i, chatbotContent: v, isLastContent: i == displayedChatbotList.length - 1, isLoading: isLoading, answeredIDs: answeredIDs, onClickLink: onClickLink, onClickChoice: onClickChoice, onClickUnsubscribeButton: onClickUnsubscribeButton }))); }),
            React.createElement("div", { ref: scrollBottomRef })),
        isDisplayTextInput && (React.createElement(UnsubscribeChatbotTextInput, { value: textValue, setValue: setTextValue, onClick: onClickSendText })))) : (React.createElement(Button, { buttonType: ButtonType.pink, className: css(styles.floatButton), onClick: onClickButton },
        React.createElement("div", { className: css(styles.floatButtonText) },
            "\u9000\u4F1A\u306E\u304A\u624B\u7D9A\u304D\u306F",
            React.createElement("br", null),
            "\u3053\u3061\u3089\u304B\u3089"),
        React.createElement("img", { className: css(styles.floatButtonIcon), src: "/assets/images/unsubscribe_chatbot/float_button_icon.png" })))));
};
var styles = StyleSheet.create({
    chatContainer: (_a = {
            backgroundColor: Colors.White,
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: '100vw',
            height: '100vh',
            boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.25)',
            zIndex: ZIndex.Overlay
        },
        _a[MediaBreakPointUp.MD] = {
            right: 40,
            bottom: 30,
            width: 402,
            height: 663,
            borderRadius: '20px 20px 0 0',
        },
        _a),
    chatHeader: (_b = {
            backgroundColor: Colors.Accent,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            height: 58,
            padding: '13px 26px'
        },
        _b[MediaBreakPointUp.MD] = {
            borderRadius: '20px 20px 0 0',
        },
        _b),
    chatHeaderText: {
        color: Colors.White,
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '140%',
    },
    closeIcon: {
        margin: 8,
    },
    chatBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        height: 'calc(100% - 58px)',
        padding: '14px 14px 100px 14px',
        overflowY: 'auto',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    chatBodyWithInput: {
        height: 'calc(100% - 102px)',
    },
    floatButton: (_c = {
            display: 'flex',
            position: 'fixed',
            right: 40,
            bottom: "calc(".concat(FIXED_FOOTER_HEIGHT, "px + 35px)"),
            width: 246,
            height: 80,
            padding: '4px 0 4px 24px',
            borderRadius: 50,
            border: "2px solid ".concat(Colors.White),
            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.25)',
            cursor: 'pointer'
        },
        _c[MediaBreakPointUp.MD] = {
            bottom: 35,
        },
        _c),
    floatButtonText: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: '130%',
        height: '100%',
    },
    floatButtonIcon: {
        position: 'absolute',
        width: 87,
        height: 90,
        top: -8,
        right: -4,
    },
});
