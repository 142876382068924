// Chart.jsで使えるカスタムプラグイン
import { Colors } from '../view/styles';
export var HorizonalLinePlugin = function () {
    return {
        id: 'horizontalLine',
        // tooltipを一番表に表示するためにhorizontalLineを先にdrawする。
        // tooltipが表示されない場合beforeTooltipDrawは呼ばれないのでafterDrawも使う
        beforeTooltipDraw: function (chart, _, options) {
            if (chart.tooltip.opacity) {
                drawHorizontalLine(chart, options);
            }
        },
        afterDraw: function (chart, _, options) {
            if (!chart.tooltip.opacity) {
                drawHorizontalLine(chart, options);
            }
        },
    };
};
var drawHorizontalLine = function (chart, options) {
    var yScale = chart.scales.y;
    var ctx = chart.ctx;
    var yAxisValue = options.yAxisValue;
    if (!yAxisValue)
        return;
    var yValue = yScale.getPixelForValue(yAxisValue);
    // テキストの描画
    var style = Colors.PrimaryDark;
    ctx.lineWidth = 1;
    ctx.fillStyle = style;
    ctx.fillText('目標', chart.chartArea.left, yValue - 10);
    // 線の描画
    ctx.beginPath();
    ctx.setLineDash([5, 5]);
    ctx.moveTo(chart.chartArea.left, yValue);
    ctx.lineTo(chart.chartArea.right, yValue);
    ctx.strokeStyle = style;
    ctx.stroke();
    // 点線をリセット（他の描画に影響しないようにするため）
    ctx.setLineDash([]);
};
