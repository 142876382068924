var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d, _e;
import * as React from 'react';
import { Logo, StepIndicator, TermsOfServiceMode } from '@lean-body/components/atoms';
import { CCForm } from '@lean-body/components/molecules/cc_form';
import { CampaignCodeFormSection, CorporationFormSection, RegistrationWithNoPaymentRequiredPlanForm, FooterComponent, PageLoading, } from '@lean-body/components/organisms';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { CorporationEmployeeParams, SignedCookieService, PaymentChannel, } from '@lean-body/src/domain';
import { LBError } from '@lean-body/src/errors';
import { CAMPAIGN_CODE_RESET, SessionStorage, SessionStorageType, SignInType, } from '@lean-body/src/infra';
import * as msg from '@lean-body/src/messages';
import { routes } from '@lean-body/src/route';
import { efUnloadEvent, isAndroidApp, useQuery, useSmMediaQuery } from '@lean-body/src/util';
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FBPixelEventName, LocalStorage } from '../../infra';
import { EF_INDICATOR_LABELS } from '../constants';
import { HIDE_HEADER_QUERY_PARAMS } from './main';
import { AMAZON_PAY_CALLBACK_QUERY_PARAMS } from './amazon_pay_callback';
import { useNavigate } from 'react-router';
import { RegistrationWithAndroid } from '../organisms/registration_with_android';
import { WebViewCallbackHandlers } from '@lean-body/src/interfaces';
var FRIEND_INVITATION_CP_PLAN_BANNER = '/assets/images/plan/amazon_campaign_banner.jpg?20240306';
var FRIEND_INVITATION_CP2_PLAN_BANNER = '/assets/images/plan/amazon_campaign_banner_cp2.png';
var AMAZON_PAY_CHECKOUT_ERROR = 'AmazonPayの決済に失敗しました。時間をおいてから再度お試しください。';
/**
 * クレジットカード登録画面
 */
var CCRegistrationPageComponent = function (_a) {
    var vm = _a.vm;
    var basePlan = vm.basePlan, secondaryBasePlan = vm.secondaryBasePlan, friendInvitationCampaign = vm.friendInvitationCampaign, campaign = vm.campaign;
    var plan = basePlan === null || basePlan === void 0 ? void 0 : basePlan.plan;
    var planName = (campaign === null || campaign === void 0 ? void 0 : campaign.isFriendInvitation) ? campaign.name : plan === null || plan === void 0 ? void 0 : plan.name;
    var nextPlan = plan === null || plan === void 0 ? void 0 : plan.nextPlan;
    var isSM = useSmMediaQuery();
    var submitLabel = (plan === null || plan === void 0 ? void 0 : plan.hasFreeTrial()) ? "".concat(plan.freeTrialString, "\u306E\u7121\u6599\u4F53\u9A13\u3092\u306F\u3058\u3081\u308B") : 'さっそく始める';
    var planBanner;
    if (campaign === null || campaign === void 0 ? void 0 : campaign.isFriendInvitation) {
        planBanner = (plan === null || plan === void 0 ? void 0 : plan.isPlanAfter2024May15PriceChange)
            ? FRIEND_INVITATION_CP2_PLAN_BANNER
            : FRIEND_INVITATION_CP_PLAN_BANNER;
    }
    else if (plan === null || plan === void 0 ? void 0 : plan.thumbnailSrc) {
        planBanner = plan.thumbnailSrc;
    }
    if (vm.loading)
        return React.createElement(PageLoading, null);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Logo, { forHeader: isSM, classObject: styles.header }),
        React.createElement("div", { className: css(styles.contentWithFooter) },
            React.createElement("div", { className: css(styles.content) },
                React.createElement(StepIndicator, { activeIdx: 1, captions: EF_INDICATOR_LABELS, classObject: styles.indicator }),
                planBanner && React.createElement("img", { src: planBanner, className: css(styles.planImg) }),
                React.createElement("div", { className: css(styles.ccform) }, isAndroidApp() ? (React.createElement(RegistrationWithAndroid, { onPressed: function () {
                        return window.flutter_inappwebview.callHandler(WebViewCallbackHandlers.registrationOpenBrowser);
                    } })) : (React.createElement(React.Fragment, null,
                    plan.isPaymentChannelMatched(PaymentChannel.None) ? ( // 課金不要のプランの場合の表示
                    React.createElement(RegistrationWithNoPaymentRequiredPlanForm, { plan: plan, onSubmit: vm.onSubmit }, !vm.hideCampaignCodeInput && (React.createElement("div", { className: css(styles.campaignCodeSection) },
                        React.createElement(CampaignCodeFormSection, { classObject: styles.campaignCode, onSubmit: vm.fetchCampaignWithPlans }))))) : (
                    // 課金が必要なプランの場合の表示
                    React.createElement("div", null,
                        React.createElement("div", { className: css(styles.title) },
                            React.createElement("p", null, "\u30D7\u30E9\u30F3\u767B\u9332"),
                            plan.isCampaignPlan() && (React.createElement("div", { className: css(styles.annotation) },
                                (friendInvitationCampaign === null || friendInvitationCampaign === void 0 ? void 0 : friendInvitationCampaign.amazonGiftCampaignIsAvailable) && (React.createElement("span", { className: css(styles.annotationBold) },
                                    "\u767B\u9332\u3092\u5B8C\u4E86\u3057\u3066Amazon\u30AE\u30D5\u30C8\u5238",
                                    vm.amazonGiftPriceStr,
                                    "\u5186\u5206\u3092",
                                    React.createElement("br", null),
                                    "\u30B2\u30C3\u30C8\u3057\u3088\u3046\uFF01",
                                    React.createElement("br", null))),
                                plan.isPlanAfter2024May15PriceChange ? (React.createElement(React.Fragment, null,
                                    planName,
                                    "\u00A0",
                                    plan.amountWithoutTax.toLocaleString(),
                                    "\u5186 \uFF08\u7A0E\u8FBC",
                                    plan.amount.toLocaleString(),
                                    "\u5186\uFF09\u306E\u3054\u767B\u9332\u306E\u65B9\u304C\u5BFE\u8C61\u3068\u306A\u308A\u307E\u3059\u3002",
                                    React.createElement("br", null),
                                    "1\u30F6\u6708\u3042\u305F\u308A",
                                    plan.amountPerMonthWithoutTax.toLocaleString(),
                                    "\u5186 /\u6708\uFF08\u7A0E\u8FBC",
                                    plan.amountPerMonth.toLocaleString(),
                                    "\u5186/\u6708\uFF09\u76F8\u5F53")) : (React.createElement(React.Fragment, null,
                                    planName,
                                    plan.amount.toLocaleString(),
                                    "\u5186(",
                                    plan.amountPerMonth,
                                    "\u5186/\u6708)\u306B\u3054\u767B\u9332\u306E\u65B9\u304C\u5BFE\u8C61\u3068\u306A\u308A\u307E\u3059\u3002"))))),
                        React.createElement(CCForm, { alertPresenter: vm.alertPresenter, onSubmit: vm.onSubmit, submitLabel: submitLabel, config: vm.config, basePlan: basePlan, secondaryBasePlan: secondaryBasePlan, onSubmitSecondaryPlan: vm.onSubmitSecondaryPlan, disableCCForm: vm.disableCCForm, campaign: vm.campaign, disableSecondaryPlan: vm.disableSecondaryPlan, signedInAmazonPay: vm.signedInAmazonPay, onSubmitAmazonPay: vm.onSubmitAmazonPay },
                            !vm.hideCampaignCodeInput && (React.createElement("div", { className: css(styles.campaignCodeSection) },
                                plan.isCorporationPlan() && (React.createElement(CorporationFormSection, { employeeCode: vm.employeeCode, employeeName: vm.employeeName, onChangeEmployeeCode: vm.onChangeEmployeeCode, onChangeEmployeeName: vm.onChangeEmployeeName })),
                                React.createElement(CampaignCodeFormSection, { classObject: styles.campaignCode, onSubmit: vm.fetchCampaignWithPlans }))),
                            React.createElement("div", { className: css(styles.termText) },
                                plan.isCampaignPlan() && (React.createElement(React.Fragment, null,
                                    plan.isPlanAfter2024May15PriceChange ? (React.createElement(React.Fragment, null,
                                        "\u7FCC\u5E74\u66F4\u65B0\u6642\u3082\u5F15\u304D\u7D9A\u304D",
                                        plan.name,
                                        "\u304C\u9069\u7528\u3068\u306A\u308A\u307E\u3059\u3002")) : (React.createElement(React.Fragment, null,
                                        "\u7FCC\u5E74\u66F4\u65B0\u6642\u304B\u3089",
                                        nextPlan.name,
                                        nextPlan.amount.toLocaleString(),
                                        "\u5186\u304C\u9069\u5FDC\u3068\u306A\u308A\u307E\u3059\u3002")),
                                    React.createElement("br", null))),
                                "\u203B\u81EA\u52D5\u66F4\u65B0\u3068\u306A\u308B\u305F\u3081\u3001\u7D99\u7D9A\u3092\u3054\u5E0C\u671B\u3055\u308C\u306A\u3044\u5834\u5408\u306F\u8A2D\u5B9A\u304B\u3089\u9000\u4F1A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002")))),
                    React.createElement("div", { className: css(styles.logoutWrap), onClick: vm.onClickSignOut }, "\u30ED\u30B0\u30A2\u30A6\u30C8"))))),
            React.createElement(FooterComponent, { termsOfServiceMode: vm.termsOfServiceMode }))));
};
export var CCRegistrationPage = observer(CCRegistrationPageComponent);
var CCRegistrationPageStatus;
(function (CCRegistrationPageStatus) {
    CCRegistrationPageStatus[CCRegistrationPageStatus["init"] = 0] = "init";
    CCRegistrationPageStatus[CCRegistrationPageStatus["submitting"] = 1] = "submitting";
    CCRegistrationPageStatus[CCRegistrationPageStatus["finished"] = 2] = "finished";
    CCRegistrationPageStatus[CCRegistrationPageStatus["failed"] = 3] = "failed";
})(CCRegistrationPageStatus || (CCRegistrationPageStatus = {}));
var Status = CCRegistrationPageStatus;
var CCRegistrationPageVM = /** @class */ (function () {
    function CCRegistrationPageVM(accountService, apiClient, alertPresenter, config, tagClient, adlocateClient, tracker) {
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "signedCookieService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "submitPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        }); // submitのリクエストのみ使用
        Object.defineProperty(this, "termsOfServiceMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: TermsOfServiceMode.normal
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "campaign", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "friendInvitationCampaign", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "employeeCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "employeeName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "openAnotherPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "hideCampaignCodeInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "tagClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "adlocateClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "disableCCForm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "disableSecondaryPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "basePlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "secondaryBasePlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "query", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sessionStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            campaign: observable,
            friendInvitationCampaign: observable,
            termsOfServiceMode: observable,
            employeeCode: observable,
            employeeName: observable,
            openAnotherPlan: observable,
            hideCampaignCodeInput: observable,
            disableCCForm: observable,
            disableSecondaryPlan: observable,
            loading: observable,
            basePlan: observable,
            secondaryBasePlan: observable,
        });
        this.config = config;
        this.accountService = accountService;
        this.apiClient = apiClient;
        this.navigate = useNavigate();
        this.alertPresenter = alertPresenter;
        this.localStorage = new LocalStorage();
        if (this.localStorage.lpParamsHasMegalosCampaignCode) {
            this.termsOfServiceMode = TermsOfServiceMode.megalos;
        }
        this.tagClient = tagClient;
        this.adlocateClient = adlocateClient;
        this.tracker = tracker;
        this.signedCookieService = new SignedCookieService(this.apiClient);
        this.query = useQuery();
        this.init();
        this.bindEvents();
        this.tracker.trackPixelEvent(FBPixelEventName.EVENT_EF3);
        this.adlocateClient.loadViewScript();
        this.sessionStorage = new SessionStorage();
        this.showErrorMessage();
    }
    Object.defineProperty(CCRegistrationPageVM.prototype, "init", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var campaignCode;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.accountService.fetchMe().then(function (me) {
                                _this.me = me;
                            });
                            // キャンペーンLPからの遷移であっても、月額との比較などで、プランが必要になるのでデフォルトのプランは必ず取得する
                            return [4 /*yield*/, this.fetchDefaultPlans()];
                        case 1:
                            // キャンペーンLPからの遷移であっても、月額との比較などで、プランが必要になるのでデフォルトのプランは必ず取得する
                            _a.sent();
                            campaignCode = this.localStorage.loadCampaingCode;
                            if (!campaignCode) return [3 /*break*/, 3];
                            this.hideCampaignCodeInput = true;
                            return [4 /*yield*/, this.fetchCampaignWithPlans(campaignCode)];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3:
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "fetchDefaultPlans", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.apiClient
                            .fetchRegistablePlans(this.query.get('campaign_code') == CAMPAIGN_CODE_RESET)
                            .then(function (basePlans) {
                            _this.basePlan = basePlans[0];
                            _this.submitPlan = _this.basePlan.plan;
                            _this.secondaryBasePlan = _this.findSecondaryBasePlan(basePlans);
                        })
                            .catch(function () {
                            _this.alertPresenter.showMessage(msg.PLAN_INDEX_FAILED);
                        })];
                });
            });
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "amazonGiftPriceStr", {
        get: function () {
            return this.friendInvitationCampaign ? this.friendInvitationCampaign.amazonGiftPrice.toLocaleString() : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "fetchCampaignWithPlans", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (campaignCode) {
            return __awaiter(this, void 0, void 0, function () {
                var campaign, basePlans, secondaryPlan, _a;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.apiClient.fetchCampaignWithPlans(campaignCode).catch(function () {
                                _this.alertPresenter.showMessage(msg.CAMPAIGN_CODE_NOT_FOUND);
                            })];
                        case 1:
                            campaign = _b.sent();
                            if (!campaign)
                                return [2 /*return*/, null];
                            basePlans = campaign.basePlans;
                            this.campaign = campaign;
                            this.basePlan = basePlans[0];
                            this.submitPlan = this.basePlan.plan;
                            secondaryPlan = this.findSecondaryBasePlan(basePlans);
                            if (secondaryPlan) {
                                this.secondaryBasePlan = secondaryPlan;
                            }
                            else {
                                this.disableSecondaryPlan = true;
                            }
                            if (!campaign.isFriendInvitation) return [3 /*break*/, 3];
                            _a = this;
                            return [4 /*yield*/, this.apiClient.getFriendInvitationCampaign()];
                        case 2:
                            _a.friendInvitationCampaign = _b.sent();
                            _b.label = 3;
                        case 3: return [2 /*return*/, campaign];
                    }
                });
            });
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "findSecondaryBasePlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (plans) {
            var _this = this;
            // 他のプランとして表示するプランは月額プランのみを対象にする
            return plans.find(function (v) { return v.plan !== _this.basePlan.plan && v.plan.isStandardPlan(); });
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "onSubmitSecondaryPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (stripeSourceToken) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.submitPlan = this.secondaryBasePlan.plan;
                    return [2 /*return*/, this.onSubmit(stripeSourceToken)];
                });
            });
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "onSubmit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (stripeSourceToken) {
            return __awaiter(this, void 0, void 0, function () {
                var corporationParams, hasErro;
                var _this = this;
                return __generator(this, function (_a) {
                    this.setStatus(Status.submitting);
                    if (this.submitPlan.isCorporationPlan()) {
                        corporationParams = new CorporationEmployeeParams(this.employeeName, this.employeeCode);
                        hasErro = this.validateCorporationParams(corporationParams);
                        if (hasErro) {
                            this.setStatus(Status.failed);
                            return [2 /*return*/];
                        }
                    }
                    return [2 /*return*/, this.accountService
                            .registerPayment(stripeSourceToken, this.submitPlan.stripePlanId, corporationParams, this.localStorage.loadInvitationCode)
                            .then(function (usrPayment) {
                            var _a;
                            _this.signedCookieService.fetchSignedCookies(); // 動画を閲覧するための署名付きCookie取得
                            _this.setStatus(Status.finished);
                            window.removeEventListener('beforeunload', efUnloadEvent);
                            // BigQueryのuser_paymentsにログを送信
                            _this.tracker.trackPixelEvent(FBPixelEventName.COMPLET_EREGISTRATION);
                            _this.tracker.trackCCRegistered(_this.me.id, usrPayment, (_a = _this.campaign) === null || _a === void 0 ? void 0 : _a.campaignCode, _this.localStorage.loadReloclubKid);
                            _this.trackAdlocateConversion();
                            _this.localStorage.removeAfterCCRegistration();
                            if (_this.localStorage.loadSignInType === SignInType.email) {
                                return _this.navigate(routes.CHECK_EMAIL.compile());
                            }
                            else {
                                _this.navigate("".concat(routes.MATCHING_ASSESSMENT_INTRO.compile(), "?").concat(HIDE_HEADER_QUERY_PARAMS, "=true"));
                            }
                        })
                            .catch(function (err) {
                            console.log(err);
                            _this.alertPresenter.showError(err, msg.CC_REGISTER_FAILED);
                            _this.setStatus(Status.failed);
                        })];
                });
            });
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "signedInAmazonPay", {
        get: function () {
            return !!this.query.get(AMAZON_PAY_CALLBACK_QUERY_PARAMS);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "onSubmitAmazonPay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (planId) {
            var _this = this;
            var _a;
            // SignupページでAmazonPayボタンを押して、CCREgistrationページまで遷移してきた時のみ使えるmethod
            this.sessionStorage.setAmazonPayParams(planId, (_a = this.campaign) === null || _a === void 0 ? void 0 : _a.campaignCode);
            window.removeEventListener('beforeunload', efUnloadEvent);
            this.apiClient
                .checkoutFromAmazonPay(this.query.get(AMAZON_PAY_CALLBACK_QUERY_PARAMS), planId)
                .then(function (url) {
                // AmazonPayリダイレクト用のURLに遷移
                // 遷移後に自動で /amazon_pay_callback に遷移して、決済が完了します
                window.location.href = url;
            })
                .catch(function (e) {
                // AmazonPayの決済ページで、決済を実行せずに「販売事業サイトに戻る」というボタンを押すと、決済に失敗するCheckoutSessionになり、最初からやり直す必要があります
                // このエラーハンドリングでは最初からやり直せるようにクエリやセッションのパラメータを削除して、プラン選択ページに遷移しています
                _this.alertPresenter.showError(null, AMAZON_PAY_CHECKOUT_ERROR);
                _this.sessionStorage.remove(SessionStorageType.AamazonPayParams);
                _this.query.delete(AMAZON_PAY_CALLBACK_QUERY_PARAMS);
                _this.navigate("".concat(routes.CC_REGISTRATION.compile(), "?").concat(_this.query.toString()));
                throw e;
            });
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "trackAdlocateConversion", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.adlocateClient.loadConversionScript(this.me.id, this.me.email);
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "onClickSignOut", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.accountService.signOut();
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "onChangeEmployeeCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            this.employeeCode = e.target.value.trim();
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "onChangeEmployeeName", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            this.employeeName = e.target.value.trim();
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "validateCorporationParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            var _this = this;
            var validateResult = params.validate();
            validateResult.errors.forEach(function (error) {
                _this.alertPresenter.showError(new LBError(), error);
            });
            return !validateResult.isValid;
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "showErrorMessage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var message = this.query.get('errorMessage');
            if (message) {
                this.alertPresenter.showError(new LBError(), message);
            }
        }
    });
    /**
     * private methods
     */
    Object.defineProperty(CCRegistrationPageVM.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.addEventListener('beforeunload', efUnloadEvent);
        }
    });
    Object.defineProperty(CCRegistrationPageVM.prototype, "setStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (status) {
            switch (status) {
                case Status.submitting:
                    this.disableCCForm = true;
                    break;
                case Status.finished:
                    this.disableCCForm = false;
                    break;
                case Status.failed:
                    this.disableCCForm = false;
                    break;
                default:
                    this.disableCCForm = false;
                    break;
            }
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Promise)
    ], CCRegistrationPageVM.prototype, "fetchCampaignWithPlans", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Promise)
    ], CCRegistrationPageVM.prototype, "onSubmitSecondaryPlan", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Promise)
    ], CCRegistrationPageVM.prototype, "onSubmit", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], CCRegistrationPageVM.prototype, "onSubmitAmazonPay", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], CCRegistrationPageVM.prototype, "onClickSignOut", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CCRegistrationPageVM.prototype, "onChangeEmployeeCode", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CCRegistrationPageVM.prototype, "onChangeEmployeeName", null);
    return CCRegistrationPageVM;
}());
export { CCRegistrationPageVM };
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 80,
        },
        _a),
    header: (_b = {},
        _b[MediaBreakPointUp.MD] = {
            width: 271,
            margin: '0 auto 18px',
        },
        _b),
    contentWithFooter: (_c = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 'calc(100vh - 50px)',
            paddingTop: 40
        },
        _c[MediaBreakPointUp.MD] = {
            minHeight: 'calc(100vh - 146px)',
            paddingTop: 0,
        },
        _c),
    content: (_d = {
            width: '100%'
        },
        _d[MediaBreakPointUp.MD] = {
            borderRadius: 10,
            maxWidth: 380,
            margin: '0 auto 40px',
            paddingTop: 40,
        },
        _d),
    indicator: {
        margin: '0 auto 53px',
    },
    planImg: {
        width: '100%',
        marginBottom: 30,
    },
    ccform: (_e = {
            padding: '0 24px',
            fontSize: 14,
            textAlign: 'center'
        },
        _e[MediaBreakPointUp.MD] = {
            padding: 0,
        },
        _e),
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        marginBottom: 30,
    },
    campaignCodeSection: {
        margin: '30px 0 0',
    },
    campaignCode: {
        padding: '14px 0 14px',
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
        borderTop: "1px solid ".concat(Colors.BorderLightGray),
    },
    annotation: {
        fontWeight: 500,
        fontSize: 14,
        marginTop: 8,
    },
    annotationBold: {
        fontWeight: 'bold',
    },
    logoutWrap: {
        fontSize: 14,
        fontWeight: 'bold',
        cursor: 'pointer',
        margin: '30px 0 40px',
    },
    termText: {
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '19px',
        color: Colors.Black,
        textAlign: 'center',
        marginTop: 30,
    },
});
