import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType } from '../atoms';
import { Colors } from '../styles';
import { WebViewCallbackHandlers } from '@lean-body/src/interfaces';
import { SessionStorage } from '@lean-body/src/infra';
export var HealthCareConnect = function (_a) {
    var classObject = _a.classObject;
    var sessionStorage = new SessionStorage();
    var hasAnyOnePermission = sessionStorage.loadAndroidAnyHealthConnectPermission();
    var _b = React.useState(false), loading = _b[0], setLoading = _b[1];
    var onClickButton = function () {
        window.flutter_inappwebview.callHandler(WebViewCallbackHandlers.requestHealthConnectPermission);
        if (!hasAnyOnePermission) {
            setLoading(true);
        }
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.content) },
            React.createElement("img", { className: css(styles.image), src: "/assets/images/health_connect.png" }),
            React.createElement("div", { className: css(styles.text) }, hasAnyOnePermission ? (React.createElement(React.Fragment, null, "\u30D8\u30EB\u30B9\u30B3\u30CD\u30AF\u30C8\u9023\u643A\u306F\u5B8C\u4E86\u3057\u3066\u3044\u307E\u3059\u3002\u9023\u643A\u3059\u308B\u3068\u30C7\u30FC\u30BF\u306E\u8AAD\u307F\u51FA\u3057\u304C\u53EF\u80FD\u306B\u306A\u308A\u307E\u3059\u3002")) : (React.createElement(React.Fragment, null,
                "\u73FE\u5728\u3001\u30D8\u30EB\u30B9\u30B3\u30CD\u30AF\u30C8\u30A2\u30D7\u30EA\u3068\u9023\u643A\u3057\u3066\u3044\u307E\u305B\u3093\u3002",
                React.createElement("br", null),
                "\u9023\u643A\u3059\u308B\u3068\u30C7\u30FC\u30BF\u306E\u8AAD\u307F\u51FA\u3057\u304C\u53EF\u80FD\u306B\u306A\u308A\u307E\u3059\u3002"))),
            React.createElement(Button, { buttonType: ButtonType.primary, loading: loading, className: css(styles.button), onClick: onClickButton }, hasAnyOnePermission ? '設定状況を確認' : '設定する')),
        React.createElement("div", { className: css(styles.annotation) }, "\u203B\u30D8\u30EB\u30B9\u30B3\u30CD\u30AF\u30C8\u304C\u7AEF\u672B\u5185\u306B\u306A\u3044\u5834\u5408\u3001GooglePlay\u304C\u958B\u304F\u306E\u3067\u3001\u30D8\u30EB\u30B9\u30B3\u30CD\u30AF\u30C8\u306E\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002")));
};
var styles = StyleSheet.create({
    container: {
        padding: '24px 0',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 20,
    },
    image: {
        width: 64,
        height: 64,
    },
    text: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    button: {
        width: '100%',
        height: 48,
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
        padding: '11px 0',
        borderRadius: 24,
    },
    annotation: {
        color: Colors.GrayDarkest,
        textAlign: 'center',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.5,
        marginTop: 4,
    },
});
