var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Chart, registerables, } from 'chart.js';
import { Line } from 'react-chartjs-2';
import * as dayjs from 'dayjs';
import { Colors } from '../styles';
import { LoadingIndicator } from '../atoms';
import { useIsMounted } from '@lean-body/src/util';
import { HorizonalLinePlugin } from '@lean-body/src/util/chart_js_plugin';
Chart.register.apply(Chart, registerables);
Chart.register(HorizonalLinePlugin());
export var CHART_LENGTH_FOR_WEEKLY = 7;
export var CHART_LENGTH_FOR_MONTHLY = 30;
export var CHART_LENGTH_FOR_QUARTERLY = 90;
export var CHART_PAGE_START = 1;
var LABEL_WORKOUT_CALORIE = '消費カロリー:kcal';
var LABEL_STEP = '歩数:歩';
var LABEL_MEAL_CALORIE = '摂取カロリー:kcal';
var LABEL_WEIGHT = '体重:kg';
var LABEL_BODY_FAT = '体脂肪率:%';
var WEEKLY_MAX_TICKS_LIMIT = 7;
var MONTHLY_MAX_TICKS_LIMIT = 4;
var THREE_MONTH_MAX_TICKS_LIMIT = 3;
var PADDING_FOR_HORIZONTAL_LINE = 30;
export var ActivityTabs = {
    daily: '日',
    weekly: '週',
    monthly: '月',
    quarterly: '3ヶ月',
};
export var LifetimeActivityTabs = {
    weekly: '週',
    monthly: '月',
    threeMonth: '3ヶ月',
};
export var LifetimeActivityType = {
    workoutCalorie: 'workout_calorie',
    steps: 'steps',
    weight: 'weight',
    mealCalorie: 'meal_calorie',
};
export var LifetimeActivityUnit = {
    workoutCalorie: 'kcal',
    steps: '歩',
    weight: 'kg',
    bodyFat: '%',
    mealCalorie: 'kcal',
};
export var ChartSwipe = {
    right: 'right',
    left: 'left',
};
export var LifetimeActivityChart = function (_a) {
    var workoutCalories = _a.workoutCalories, weightsAndBodyFatActivities = _a.weightsAndBodyFatActivities, stepActivities = _a.stepActivities, mealCalories = _a.mealCalories, target = _a.target, tab = _a.tab, rightmostDate = _a.rightmostDate, horizontalLineValue = _a.horizontalLineValue, leftGraphScales = _a.leftGraphScales, rightGraphScales = _a.rightGraphScales, classObject = _a.classObject;
    var isMounted = useIsMounted();
    var today = dayjs().startOf('day');
    var vm = new LifeTimeActivityChartVM(workoutCalories, weightsAndBodyFatActivities, stepActivities, mealCalories, today);
    // x軸の個数(chartLength)
    var chartLength = vm.tabToChartLength(tab);
    // レッスン時間の棒グラフのデザイン調整
    var barBorderRadius = tab === ActivityTabs.weekly ? 6 : 2;
    // 体重の折れ線グラフのデザイン調整
    var pointRadius = vm.tabToPointRadius(tab, chartLength);
    // 描画するまでロードを表示するための変数
    var _b = React.useState(false), showable = _b[0], setShowable = _b[1];
    // x軸のデータをmap
    var labels = vm.buildXLabels(chartLength, tab, dayjs(rightmostDate).startOf('day'));
    // y軸のデータをmap
    var stepValues = vm.buildSteps(chartLength);
    var weightValues = vm.buildWeightValues(chartLength, tab);
    var bodyFatValues = vm.buildBodyFatValues(chartLength, tab);
    var workoutCalorieValues = vm.buildWorkoutCalorieValues(chartLength);
    var mealCalorieValues = vm.buildMealCalorieValues(chartLength);
    var datasets = function () {
        if (target === LifetimeActivityType.workoutCalorie) {
            return [
                {
                    label: LABEL_WORKOUT_CALORIE,
                    type: 'bar',
                    data: workoutCalorieValues,
                    backgroundColor: Colors.PrimaryDark,
                    yAxisID: 'y',
                },
            ];
        }
        if (target === LifetimeActivityType.steps) {
            return [
                {
                    label: LABEL_STEP,
                    type: 'bar',
                    data: stepValues,
                    backgroundColor: Colors.PrimaryDark,
                    yAxisID: 'y',
                },
            ];
        }
        if (target === LifetimeActivityType.mealCalorie) {
            return [
                {
                    label: LABEL_MEAL_CALORIE,
                    type: 'bar',
                    data: mealCalorieValues,
                    backgroundColor: Colors.PrimaryDark,
                    yAxisID: 'y',
                },
            ];
        }
        if (target === LifetimeActivityType.weight) {
            return [
                {
                    label: LABEL_WEIGHT,
                    type: 'line',
                    data: weightValues,
                    borderColor: Colors.PrimaryDark,
                    borderWidth: 1,
                    backgroundColor: Colors.PrimaryDark,
                    pointRadius: pointRadius,
                    pointBorderColor: Colors.PrimaryDark,
                    pointBorderWidth: 1,
                    yAxisID: 'y',
                },
                {
                    label: LABEL_BODY_FAT,
                    type: 'line',
                    data: bodyFatValues,
                    borderColor: Colors.Orange,
                    borderWidth: 1.5,
                    backgroundColor: Colors.Orange,
                    pointRadius: pointRadius,
                    pointBorderColor: Colors.Orange,
                    pointBorderWidth: 1.5,
                    yAxisID: 'rightY',
                },
            ];
        }
        return [];
    };
    var scales = function () {
        // 左側のY軸
        var yMin = leftGraphScales && target === LifetimeActivityType.weight ? leftGraphScales[0] : 0;
        var yMax = leftGraphScales ? leftGraphScales[leftGraphScales.length - 1] : 0;
        var step = leftGraphScales
            ? target === LifetimeActivityType.weight
                ? (yMax - yMin) / (leftGraphScales.length - 1)
                : yMax / (leftGraphScales.length - 1)
            : 0;
        // 右側のY軸
        var rightYMin = rightGraphScales && target === LifetimeActivityType.weight ? rightGraphScales[0] : 0;
        var rightYMax = rightGraphScales ? rightGraphScales[rightGraphScales.length - 1] : 0;
        var rightStep = rightGraphScales
            ? target === LifetimeActivityType.weight
                ? (rightYMax - rightYMin) / (rightGraphScales.length - 1)
                : rightYMax / (rightGraphScales.length - 1)
            : 0;
        if (target === LifetimeActivityType.workoutCalorie ||
            target === LifetimeActivityType.steps ||
            target === LifetimeActivityType.weight ||
            target === LifetimeActivityType.mealCalorie) {
            return {
                x: {
                    grid: {
                        display: false, // x軸の縦線を非表示にするオプション
                    },
                    ticks: __assign(__assign({}, scaleFont), { minRotation: 0, maxRotation: 0, autoSkip: vm.isAutoSkip(tab), maxTicksLimit: vm.maxTicksLimit(tab) }),
                },
                y: {
                    display: true,
                    position: 'left',
                    max: yMax,
                    min: yMin,
                    ticks: __assign(__assign({}, scaleFont), { stepSize: step, callback: function (label) {
                            return target === LifetimeActivityType.weight ? label : Math.floor(label);
                        } }),
                    grid: {
                        drawTicks: false, // 目盛線を非表示にする
                        color: Colors.BackgroundGrayC,
                    },
                    border: {
                        display: false, // 目盛の軸線を非表示にする
                    },
                },
                rightY: {
                    display: !!rightGraphScales,
                    position: 'right',
                    max: rightYMax,
                    min: rightYMin,
                    ticks: __assign(__assign({}, scaleFont), { stepSize: rightStep }),
                    grid: {
                        drawTicks: false, // 目盛線を非表示にする
                        color: Colors.BackgroundGrayC,
                    },
                    border: {
                        display: false, // 目盛の軸線を非表示にする
                    },
                },
            };
        }
        return {};
    };
    // グラフと凡例の間に余白を追加する
    var addPaddingPlugin = {
        id: 'addPadding',
        beforeInit: function (chart) {
            var originalFit = chart.legend.fit;
            chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
                this.height += 10;
            };
        },
    };
    // 体重用の凡例を追加する
    var weightLabelPlugin = {
        id: 'weightLabel',
        afterDraw: function (chart) {
            var ctx = chart.ctx;
            // 左側の凡例（体重）
            ctx.fillStyle = Colors.PrimaryDark;
            ctx.beginPath();
            ctx.arc(chart.chartArea.left - 10, chart.chartArea.top - 24, 5, 0, 2 * Math.PI);
            ctx.fill();
            ctx.fillStyle = Colors.Black;
            ctx.fontSize = 10;
            ctx.fillText(LABEL_WEIGHT, chart.chartArea.left - 2, chart.chartArea.top - 20);
            // 右側の凡例（体脂肪率）
            var rightPosition = chart.chartArea.right - ctx.measureText(LABEL_BODY_FAT).width + 8;
            ctx.fillStyle = Colors.Orange;
            ctx.beginPath();
            ctx.arc(rightPosition, chart.chartArea.top - 24, 5, 0, 2 * Math.PI);
            ctx.fill();
            ctx.fillStyle = Colors.Black;
            ctx.fontSize = 10;
            ctx.fillText(LABEL_BODY_FAT, rightPosition + 8, chart.chartArea.top - 20);
            ctx.restore();
        },
    };
    return (React.createElement("div", { className: css(styles.container, showable && styles.showable, classObject) },
        React.createElement(Line, { data: {
                labels: labels,
                datasets: datasets(),
            }, options: {
                aspectRatio: 1.8, // canvasの縦横の比率
                elements: {
                    bar: {
                        borderRadius: barBorderRadius, // 棒グラフの角丸
                    },
                    line: {
                        spanGaps: true, // y軸で未入力の場所があってもskipしないために必要なオプション
                    },
                },
                scales: scales(),
                plugins: {
                    legend: {
                        // 凡例のオプション
                        reverse: true,
                        align: 'start',
                        labels: {
                            usePointStyle: true, // generateLabels()で pointStyle を使うのに必要なオプション
                            boxWidth: 7,
                            boxHeight: 8,
                            color: Colors.Black,
                            generateLabels: vm.generateLabels,
                        },
                        onClick: null, // nullを明示的に設定しないと、クリックが何かを発火しようとしてnullエラーになるので設定
                        display: target !== LifetimeActivityType.weight,
                    },
                    tooltip: {
                        callbacks: {
                            label: vm.tooltipLabel,
                        },
                    },
                    horizontalLine: {
                        yAxisValue: horizontalLineValue,
                    },
                },
                animation: {
                    onComplete: function () {
                        if (isMounted) {
                            setShowable(true);
                        }
                    },
                },
                layout: {
                    padding: {
                        top: target === LifetimeActivityType.weight && 30,
                        right: !!horizontalLineValue && target !== LifetimeActivityType.weight && PADDING_FOR_HORIZONTAL_LINE, // 目標テキスト分の余白
                    },
                },
            }, plugins: [target === LifetimeActivityType.weight ? weightLabelPlugin : addPaddingPlugin] }),
        !showable && React.createElement(LoadingIndicator, { className: css(styles.loading), size: 40 })));
};
var LifeTimeActivityChartVM = /** @class */ (function () {
    function LifeTimeActivityChartVM(workoutCaloriesList, weightsAndBodyFat, stepActivities, mealCalories, today) {
        Object.defineProperty(this, "today", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonMinutes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weights", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bodyFat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "steps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "workoutCalories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mealCalories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.today = today;
        this.weights = weightsAndBodyFat.map(function (v) { return v.weight; });
        this.bodyFat = weightsAndBodyFat.map(function (v) { return v.bodyFat; });
        this.steps = stepActivities.map(function (v) { return v.steps; });
        this.workoutCalories = workoutCaloriesList.map(function (v) { return v.calories; });
        this.mealCalories = mealCalories.map(function (v) { return v.calories; });
    }
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildSteps", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength) {
            return this.steps.slice(0, chartLength).reverse();
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildWorkoutCalorieValues", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength) {
            return this.workoutCalories.slice(0, chartLength).reverse();
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildMealCalorieValues", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength) {
            return this.mealCalories.slice(0, chartLength).reverse();
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildWeightValues", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength, tab) {
            var targets = this.weights.slice(0, chartLength);
            var lastWeight = targets.find(function (v) { return v; });
            var firstWeight = __spreadArray([], targets, true).reverse().find(function (v) { return v; });
            return targets.reverse().map(function (v, i) {
                if (!v) {
                    // グラフの最後の体重が入力されていない場合は、最後に入力した体重を反映させる
                    if (i === chartLength - 1) {
                        return lastWeight || NaN;
                    }
                    // グラフの最初の体重が入力されていない場合は、最初に入力した体重を反映させる(週のみ)
                    if (i === 0 && tab === ActivityTabs.weekly) {
                        return firstWeight || NaN;
                    }
                }
                return v || NaN;
            });
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildBodyFatValues", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength, tab) {
            var targets = this.bodyFat.slice(0, chartLength);
            var lastBodyFat = targets.find(function (v) { return v; });
            var firstBodyFat = __spreadArray([], targets, true).reverse().find(function (v) { return v; });
            return targets.reverse().map(function (v, i) {
                if (!v) {
                    // グラフの最後の体脂肪率が入力されていない場合は、最後に入力した体脂肪率を反映させる
                    if (i === chartLength - 1) {
                        return lastBodyFat || NaN;
                    }
                    // グラフの最初の体脂肪率が入力されていない場合は、最初に入力した体脂肪率を反映させる(週のみ)
                    if (i === 0 && tab === ActivityTabs.weekly) {
                        return firstBodyFat || NaN;
                    }
                }
                return v || NaN;
            });
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "tabToChartLength", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            if (v === ActivityTabs.weekly) {
                return CHART_LENGTH_FOR_WEEKLY;
            }
            else if (v === ActivityTabs.monthly) {
                return CHART_LENGTH_FOR_MONTHLY;
            }
            else {
                return CHART_LENGTH_FOR_QUARTERLY;
            }
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "tabToPointRadius", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tab, chartLength) {
            var result;
            if (tab === ActivityTabs.weekly) {
                result = new Array(chartLength).fill(4.5);
                var firstWeight = this.weights.slice(0, chartLength).reverse()[0];
                // 先頭の体重が入力されていない場合は、0にして点を表示しない
                if (!firstWeight) {
                    result[0] = 0;
                }
            }
            else {
                result = new Array(chartLength - 1).fill(0);
                if (tab === ActivityTabs.monthly) {
                    result.push(3);
                }
                else {
                    result.push(2);
                }
            }
            return result;
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildXLabels", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength, tab, day) {
            return __spreadArray([], Array(chartLength), true).map(function (_, i) {
                var targetTime = day.add(-i, 'day');
                if (tab === ActivityTabs.quarterly) {
                    // 3ヶ月表示の時は左端とx月1日の時にラベルを表示する
                    // 左端は15日より前のみ表示する（15日以降は翌月1日のラベルと被る可能性があるので）
                    if (targetTime.date() === 1 || (i === chartLength - 1 && targetTime.date() <= 15)) {
                        return "".concat(targetTime.year(), ".").concat(targetTime.month() + 1);
                    }
                    else {
                        return '';
                    }
                }
                else {
                    return targetTime.format('M/D');
                }
            })
                .reverse();
        }
    });
    // 凡例のデザインのパラメータ
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "generateLabels", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chart) {
            return chart.data.datasets.map(function (dataset) {
                return {
                    text: dataset.label,
                    // 凡例の文字色
                    fontColor: Colors.TextDark,
                    // 凡例のboxの塗りつぶし
                    fillStyle: dataset.backgroundColor,
                    // 凡例のboxのborder(周囲)の太さ
                    lineWidth: dataset.type === 'bar' ? 3 : dataset.pointBorderWidth,
                    // 凡例のboxのborder(周囲)の塗りつぶし色
                    strokeStyle: dataset.type === 'bar' ? dataset.backgroundColor : dataset.pointBorderColor,
                    // 凡例のboxの形, rectRounded=角丸四角, circle=丸
                    pointStyle: dataset.type === 'bar' ? 'rectRounded' : 'circle',
                };
            });
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "isAutoSkip", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tab) {
            return tab === ActivityTabs.monthly;
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "maxTicksLimit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tab) {
            switch (tab) {
                case ActivityTabs.weekly:
                    return WEEKLY_MAX_TICKS_LIMIT;
                case ActivityTabs.monthly:
                    return MONTHLY_MAX_TICKS_LIMIT;
                case ActivityTabs.quarterly:
                    return THREE_MONTH_MAX_TICKS_LIMIT;
            }
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "tooltipLabel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (data) {
            var unit;
            switch (data.dataset.label) {
                case LABEL_WORKOUT_CALORIE:
                    unit = LifetimeActivityUnit.workoutCalorie;
                    break;
                case LABEL_STEP:
                    unit = LifetimeActivityUnit.steps;
                    break;
                case LABEL_MEAL_CALORIE:
                    unit = LifetimeActivityUnit.mealCalorie;
                    break;
                case LABEL_WEIGHT:
                    unit = LifetimeActivityUnit.weight;
                    break;
                case LABEL_BODY_FAT:
                    unit = LifetimeActivityUnit.bodyFat;
                    break;
            }
            return "".concat(data.formattedValue, " ").concat(unit);
        }
    });
    return LifeTimeActivityChartVM;
}());
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        visibility: 'hidden',
    },
    showable: {
        visibility: 'visible',
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        visibility: 'visible',
    },
});
var scaleFont = {
    // x軸,y軸の文字のcss
    color: Colors.GrayDarkest,
    font: {
        size: 11,
        weight: '500',
    },
};
