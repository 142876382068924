import DOMPurify from 'dompurify';
import { ChatbotChoice } from './chatbot_choice';
import { ChatbotDescription } from './chatbot_description';
var ChatbotSection = /** @class */ (function () {
    function ChatbotSection(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "choiceIconUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isEnd", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "descriptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "choices", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = data.id;
        this.choiceIconUrl = data.choiceIconUrl;
        this.title = data.title;
        this.isEnd = data.isEnd;
        this.descriptions = data.descriptions.map(function (v) { return new ChatbotDescription(v); });
        this.choices = data.choices.map(function (v) { return new ChatbotChoice(v); });
    }
    return ChatbotSection;
}());
export { ChatbotSection };
var ChatbotContent = /** @class */ (function () {
    function ChatbotContent(config, sectionID, descriptionID, isAnswer, choiceIconUrl, isUnsubscribeButton, text, imageUrl, imageLinkUrl, nextSectionID, linkUrl, choices) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sectionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "descriptionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isAnswer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "choiceIconUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isUnsubscribeButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "text", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "imageUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "imageLinkUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextSectionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "linkUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "choices", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.config = config;
        this.sectionID = sectionID;
        this.descriptionID = descriptionID;
        this.isAnswer = isAnswer;
        this.choiceIconUrl = choiceIconUrl;
        this.isUnsubscribeButton = isUnsubscribeButton;
        this.text = DOMPurify.sanitize(text, { ADD_ATTR: ['target', 'rel'] });
        this.imageUrl = DOMPurify.sanitize(imageUrl, {
            ADD_TAGS: ['iframe'],
            ADD_ATTR: ['width', 'height', 'src', 'title', 'frameborder', 'allow', 'referrerpolicy', 'allowfullscreen'],
        });
        this.imageLinkUrl = imageLinkUrl;
        this.nextSectionID = nextSectionID;
        this.linkUrl = linkUrl;
        this.choices = choices;
    }
    Object.defineProperty(ChatbotContent.prototype, "iconURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.config.cdnBaseURL + this.choiceIconUrl;
        }
    });
    Object.defineProperty(ChatbotContent.prototype, "imageURL", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.config.cdnBaseURL + this.imageUrl;
        }
    });
    Object.defineProperty(ChatbotContent.prototype, "isIframe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.imageUrl.startsWith('<iframe');
        }
    });
    return ChatbotContent;
}());
export { ChatbotContent };
