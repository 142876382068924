export var Colors = {
    Black: '#3B434B',
    GrayDarkest: '#95A0AC',
    GrayDarkestB: '#6F6F7F',
    Gray: '#C5CBD1',
    GrayLighter: '#DDDDDD',
    GrayLight: '#F8F9F9',
    GrayLightText: '#979797',
    TextDark: '#1E2020',
    Red: '#DA5055',
    BlueLighterB: '#cff4f7',
    BlueLightest: '#F8FAFA',
    White: '#ffffff',
    CyanLightest: '#F2F6F6',
    CyanLightestB: '#F7FDFD',
    Line: '#06C755',
    Facebook: '#1877F2',
    Twitter: '#55acee',
    Yellow: '#FFD46D',
    YellowLight: '#FFF4DB',
    Orange: '#FF8956',
    Green: '#7AE06A',
    BlueHepatica: '#6364EC',
    Ivory: '#F9F6EA',
    StrictBlack: '#000000',
    // figmaに定義されているColor
    // TODO: 汎用性のない命名は置換していく
    Primary: '#65C3CA',
    PrimaryTransparent: 'rgb(101, 206, 217, 0.4)',
    GradationPrimary: 'linear-gradient(90deg, #65CED9 0%, #5FC2E1 100%)',
    GradationPrimaryTransparent: 'linear-gradient(90deg, rgba(101, 206, 217, 0.4) 0%, rgba(95, 194, 225, 0.4) 100%)',
    GradationOrange: 'linear-gradient(215.67deg, #FFD00B 1.15%, #E46113 97.91%)',
    PrimaryDark: '#65CED9',
    PrimaryDarkB: '#47CCDA',
    PrimaryLight: '#5FC2E1',
    PrimaryLighter: '#F0FAFB',
    PrimaryLighterB: '#A3E2E8',
    Accent: '#FF569D',
    DarkAccent: '#E73D66',
    Text: '#333333',
    BackgroundGrayC: '#F2F2F2',
    BackgroundGrayD: '#DEE1E4',
    BackgroundGreen: '#D6F4DD',
    BackgroundLightGray: '#F2F6F7',
    BackgroundBlue: '#E0F5F7',
    BackgroundOrange: '#F4F3E9',
    BackgroundLightBlueMagenta: '#F7F7FE',
    BackgroundDialog: 'rgba(0, 0, 0, 0.5)',
    BackgroundAccent: '#FFDAE9',
    BorderLightGray: '#EFEFEF',
    BorderLightGrayB: '#E2E6E7',
    Alert: '#FF2B5E',
    AlertLight: '#FFF3F6',
    ProgressGreen: '#37C85B',
    ProgressLightGreen: '#D6F4DD',
    ProgressBlue: '#4E95E9',
};
export var MediaBreakPointUp;
(function (MediaBreakPointUp) {
    MediaBreakPointUp["XS"] = "@media (min-width: 0px)";
    MediaBreakPointUp["SM"] = "@media (min-width: 576px)";
    MediaBreakPointUp["MD"] = "@media (min-width: 768px)";
    MediaBreakPointUp["LG"] = "@media (min-width: 992px)";
    MediaBreakPointUp["XL"] = "@media (min-width: 1300px)";
})(MediaBreakPointUp || (MediaBreakPointUp = {}));
export var MediaBreakPointDown;
(function (MediaBreakPointDown) {
    MediaBreakPointDown["XS"] = "@media (max-width: 320px)";
    MediaBreakPointDown["SM"] = "@media (max-width: 576px)";
    MediaBreakPointDown["MD"] = "@media (max-width: 768px)";
    MediaBreakPointDown["XL"] = "@media (max-width: 1299px)";
})(MediaBreakPointDown || (MediaBreakPointDown = {}));
export var Sizes = {
    MobileHeaderHeight: 50,
    iOSAppBannerHeight: 77,
    GutterWidth: '1rem',
};
