var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import * as React from 'react';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { TASK_DATE_QUERY, UserWeight } from '@lean-body/src/domain';
import { Colors, MediaBreakPointUp } from '../styles';
import { ArrowDirection, ArrowIconWithMargin, Button, ButtonType, Input } from '../atoms';
import { DailyTasksPageTaskName, routes } from '@lean-body/src/route';
import { useNavigate } from 'react-router';
import { ActivityTabs, CHART_LENGTH_FOR_MONTHLY, CHART_LENGTH_FOR_QUARTERLY, CHART_LENGTH_FOR_WEEKLY, LifetimeActivityChart, LifetimeActivityType, } from './lifetime_activity_chart';
import { GrayBoxTabs, TabTypes } from '../molecules/gray_box_tabs';
import { EditGoal } from './edit_goal';
import { Dialog } from '../molecules';
import { dateMaxToday, getFormattedToFormat, getFormattedToMonthDateDay, isAndroidApp, sameDay, useQuery, } from '@lean-body/src/util';
import { DatePagination, DatePaginationStep } from '../molecules/date_pagination';
import { WeightGoalAchievedDialog } from '../molecules/weight_goal_achieved_dialog';
import { UserBodyFat } from '@lean-body/src/domain/user_body_fat';
import { SettingItemKeys } from './setting_menu';
import { SessionStorage } from '@lean-body/src/infra';
export var WeightPageTypes = {
    detail: 'detail',
    goalEdit: 'goal_edit',
};
export var fetchDataForWeightAndBodyFat = function (tab, apiClient, date) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, Promise.all([
                apiClient.fetchDailyTaskGoal(DailyTasksPageTaskName.weight, getFormattedToFormat(date)),
                apiClient.fetchTargetWeightAndBodyFat(date),
                tab === ActivityTabs.weekly
                    ? apiClient.getUserWeightsAndBodyFat(date, CHART_LENGTH_FOR_WEEKLY)
                    : tab === ActivityTabs.monthly
                        ? apiClient.getUserWeightsAndBodyFat(date, CHART_LENGTH_FOR_MONTHLY)
                        : apiClient.getUserWeightsAndBodyFat(date, CHART_LENGTH_FOR_QUARTERLY),
            ])];
    });
}); };
export var WeightTask = function (_a) {
    var apiClient = _a.apiClient, profile = _a.profile, setProfile = _a.setProfile, containerClassObject = _a.containerClassObject, headerClassObject = _a.headerClassObject, arrowIconClassObject = _a.arrowIconClassObject, onClickBackHome = _a.onClickBackHome;
    var today = new Date();
    var query = useQuery();
    var queryDate = dayjs(query.get(TASK_DATE_QUERY) || today).toDate();
    var sessionStorage = new SessionStorage();
    var hasAnyOnePermission = sessionStorage.loadAndroidAnyHealthConnectPermission();
    var _b = useState(queryDate), date = _b[0], setDate = _b[1];
    var navigate = useNavigate();
    var _c = useState(WeightPageTypes.detail), pageType = _c[0], setPageType = _c[1];
    var _d = useState(ActivityTabs.weekly), tab = _d[0], setTab = _d[1];
    var _e = useState(''), weight = _e[0], setWeight = _e[1];
    var _f = useState(''), bodyFat = _f[0], setBodyFat = _f[1];
    var _g = useState(), placeholderWeight = _g[0], setPlaceholderWeight = _g[1];
    var _h = useState(), placeholderBodyFat = _h[0], setPlaceholderBodyFat = _h[1];
    var _j = React.useState([]), weightsAndBodyFatActivities = _j[0], setWeightsAndBodyFatActivities = _j[1];
    var _k = useState(0), weightGoalByDate = _k[0], setWeightGoalByDate = _k[1];
    var _l = useState(false), submittable = _l[0], setSubmittable = _l[1];
    var _m = useState(false), submitLoading = _m[0], setSubmitLoading = _m[1];
    var _o = useState([]), leftGraphScales = _o[0], setLeftGraphScales = _o[1];
    var _p = useState([]), rightGraphScales = _p[0], setRightGraphScales = _p[1];
    var _q = useState(''), message = _q[0], setMessage = _q[1];
    var _r = useState(DatePaginationStep.day), datePaginationStep = _r[0], setDatePaginationStep = _r[1];
    var lastFetchTime = useRef(0);
    var _s = useState(), isShowGoalAchievedDialog = _s[0], setIsShowGoalAchievedDialog = _s[1];
    useEffect(function () {
        if (pageType === WeightPageTypes.detail) {
            var fetchTime_1 = Date.now();
            lastFetchTime.current = fetchTime_1;
            fetchDataForWeightAndBodyFat(tab, apiClient, date).then(function (_a) {
                var goal = _a[0], userWeightAndBodyFat = _a[1], weightAndBodyFatActivities = _a[2];
                if (fetchTime_1 === lastFetchTime.current) {
                    setWeightGoalByDate(goal ? Number(UserWeight.formatWeight(goal.toString())) : 0);
                    // 初回リクエストの時だけ placeholder の値を変更する（期間変更やタブ変更で変更されないように）
                    if (sameDay(date, queryDate)) {
                        setPlaceholderWeight(userWeightAndBodyFat.weight);
                        setPlaceholderBodyFat(userWeightAndBodyFat.bodyFat);
                    }
                    // 対象日の体重と体脂肪率があれば初期値として入れる
                    if (sameDay(userWeightAndBodyFat.weightDateAt.toDate(), queryDate)) {
                        setWeight(userWeightAndBodyFat.weight.toString());
                    }
                    if (sameDay(userWeightAndBodyFat.bodyFatDateAt.toDate(), queryDate)) {
                        setBodyFat(userWeightAndBodyFat.bodyFat.toString());
                    }
                    setWeightsAndBodyFatActivities(weightAndBodyFatActivities.weightAndBodyFatActivityList);
                    setLeftGraphScales(weightAndBodyFatActivities.weightGraphScales);
                    setRightGraphScales(weightAndBodyFatActivities.bodyFatGraphScales);
                }
            });
        }
    }, [pageType, tab, date, datePaginationStep]);
    useEffect(function () {
        onChangeTab(tab);
    }, [tab]);
    var onChangeTab = function (key) {
        switch (key) {
            case ActivityTabs.weekly:
                setDatePaginationStep(DatePaginationStep.week);
                break;
            case ActivityTabs.monthly:
                setDatePaginationStep(DatePaginationStep.month);
                break;
            case ActivityTabs.quarterly:
                setDatePaginationStep(DatePaginationStep.quarter);
                break;
        }
    };
    var onClickSubmit = function () {
        if (!submittable)
            return;
        setSubmittable(false);
        setSubmitLoading(true);
        apiClient
            .postUserWeightAndBodyFat(Number(weight), Number(bodyFat), queryDate)
            .then(function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var showGoalAchievedDialog = _b.showGoalAchievedDialog;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, apiClient.fetchMypageProfile().then(function (v) { return setProfile(v); })];
                    case 1:
                        _c.sent();
                        if (showGoalAchievedDialog) {
                            setIsShowGoalAchievedDialog(showGoalAchievedDialog);
                        }
                        else {
                            navigate("".concat(routes.TOP.compile(), "?date=").concat(getFormattedToFormat(queryDate)));
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
    var onChangeWeight = function (event) {
        var value = UserWeight.formatWeight(event.target.value);
        setSubmittable(Number(value) > 0 || Number(bodyFat) > 0);
        setWeight(value);
    };
    var onChangeBodyFat = function (event) {
        var value = UserBodyFat.formatBodyFat(event.target.value);
        setSubmittable(Number(value) > 0 || Number(weight) > 0);
        setBodyFat(value);
    };
    var onClickEditWeightGoal = function () {
        setPageType(WeightPageTypes.goalEdit);
    };
    var onClickBack = function () {
        setPageType(WeightPageTypes.detail);
    };
    var onClickDatePagination = function (value) {
        setDate(dateMaxToday(value));
    };
    var onSaveWeightGoal = function (v) {
        var inputWeightGoal = UserWeight.formatWeight(v);
        apiClient.checkWeightGoal(profile.height, profile.weight, inputWeightGoal).then(function (result) {
            if (!result.isValid) {
                setMessage(result.description);
            }
            else {
                apiClient.saveWeightGoal(Number(inputWeightGoal)).then(function () { return setPageType(WeightPageTypes.detail); });
            }
        });
    };
    var onClickCloseErrorDialog = function () {
        setMessage('');
    };
    var onClickCloseAchievementDialog = function () {
        navigate(routes.TOP.compile());
    };
    var onClickSetNewGoal = function () {
        navigate("".concat(routes.GOAL_MATCHING_ASSESSMENT.compile(), "?change=1"));
    };
    var onClickHealthCareConnect = function () {
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.healthCare));
    };
    return (React.createElement(React.Fragment, null, pageType === WeightPageTypes.detail ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(containerClassObject) },
            React.createElement(ArrowIconWithMargin, { classObject: arrowIconClassObject, direction: ArrowDirection.left, color: Colors.TextDark, onClick: function () {
                    onClickBackHome(queryDate);
                } }),
            React.createElement("div", { className: css(headerClassObject) }, "\u4F53\u91CD\u3092\u8A18\u9332\u3059\u308B"),
            React.createElement("div", { className: css(styles.section) },
                React.createElement(GrayBoxTabs, { setTab: setTab, tab: tab, tabType: TabTypes.lifetimeActivity, classObject: styles.tab }),
                React.createElement(DatePagination, { value: date, max: today, step: datePaginationStep, onClickNext: onClickDatePagination, onClickPrev: onClickDatePagination, classObject: styles.datePagination }),
                React.createElement("div", { className: css(styles.activityContainer) },
                    React.createElement(LifetimeActivityChart, { tab: tab, target: LifetimeActivityType.weight, workoutCalories: [], stepActivities: [], mealCalories: [], weightsAndBodyFatActivities: weightsAndBodyFatActivities, rightmostDate: date, horizontalLineValue: weightGoalByDate, leftGraphScales: leftGraphScales, rightGraphScales: rightGraphScales }),
                    React.createElement("div", { className: css(styles.editGoalButtonBox) },
                        React.createElement(Button, { className: css(styles.editButton), isRadius: true, onClick: onClickEditWeightGoal }, "\u76EE\u6A19\u4F53\u91CD\u3092\u7DE8\u96C6")))),
            React.createElement("div", { className: css(styles.delimiterLine) }),
            React.createElement("div", { className: css(styles.section) },
                React.createElement("div", { className: css(styles.activityContainer) },
                    React.createElement("div", { className: css(styles.inputHeader) }, getFormattedToMonthDateDay(queryDate)),
                    React.createElement(Input, { type: "number", placeholder: (placeholderWeight === null || placeholderWeight === void 0 ? void 0 : placeholderWeight.toString()) || '体重を入力', containerClassObject: styles.inputContainer, unitLabel: "kg", onChange: onChangeWeight, hideSpinner: true, label: "\u4F53\u91CD", value: (weight === null || weight === void 0 ? void 0 : weight.toString()) || '' }),
                    React.createElement(Input, { type: "number", placeholder: (placeholderBodyFat === null || placeholderBodyFat === void 0 ? void 0 : placeholderBodyFat.toString()) || '--', containerClassObject: styles.inputContainer, unitLabel: "%", onChange: onChangeBodyFat, hideSpinner: true, label: "\u4F53\u8102\u80AA\u7387", value: (bodyFat === null || bodyFat === void 0 ? void 0 : bodyFat.toString()) || '' }),
                    isAndroidApp() && !hasAnyOnePermission && (React.createElement("div", { className: css(styles.healthConnectBox) },
                        React.createElement("div", { className: css(styles.healthConnectLink), onClick: onClickHealthCareConnect },
                            React.createElement("img", { className: css(styles.healthConnectIcon), src: "/assets/images/icons/connect_light_blue.svg" }),
                            "\u30D8\u30EB\u30B9\u30B3\u30CD\u30AF\u30C8\u304B\u3089\u81EA\u52D5\u53D6\u5F97"))),
                    React.createElement(Button, { buttonType: submittable ? ButtonType.primary : ButtonType.transparentPrimary, loading: submitLoading, isRadius: true, className: css(styles.weightSubmitButton), onClick: onClickSubmit }, "\u8A18\u9332")))),
        isShowGoalAchievedDialog && (React.createElement(WeightGoalAchievedDialog, { onClickSetNewGoal: onClickSetNewGoal, onClickCancel: onClickCloseAchievementDialog })))) : (pageType === WeightPageTypes.goalEdit && (React.createElement(React.Fragment, null,
        React.createElement(EditGoal, { title: "\u76EE\u6A19\u4F53\u91CD", unit: "kg", checkboxLabel: "\u73FE\u72B6\u7DAD\u6301", defaultValue: weightGoalByDate.toString(), keepValue: weight, isText: true, textInputType: "number", onClickBack: onClickBack, onSave: onSaveWeightGoal }),
        message && (React.createElement(Dialog, { classObject: styles.dialog, content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.message) }, message),
                React.createElement(Button, { className: css(styles.closeButton), buttonType: ButtonType.primary, onClick: onClickCloseErrorDialog }, "OK")) })))))));
};
var styles = StyleSheet.create({
    section: (_a = {
            padding: '0 24px'
        },
        _a[MediaBreakPointUp.MD] = {
            padding: 0,
        },
        _a),
    tab: {
        marginBottom: 20,
    },
    datePagination: {
        marginBottom: 14,
    },
    activityContainer: {
        width: 327,
        margin: '0 auto',
    },
    editGoalButtonBox: {
        textAlign: 'right',
        marginTop: 14,
    },
    editButton: {
        color: Colors.TextDark,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        height: 30,
        padding: '5px 12px 6px',
        ':hover': {
            backgroundColor: Colors.White,
        },
    },
    delimiterLine: {
        height: 8,
        width: '100%',
        backgroundColor: Colors.CyanLightest,
        margin: '24px 0',
    },
    inputHeader: {
        color: Colors.TextDark,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    inputContainer: {
        marginTop: 20,
    },
    healthConnectBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 2,
        marginTop: 20,
    },
    healthConnectLink: {
        color: Colors.PrimaryDarkB,
        display: 'flex',
        alignItems: 'center',
        fontSize: 10,
        fontWeight: 'bold',
        lineHeight: '100%',
        cursor: 'pointer',
    },
    healthConnectIcon: {
        width: 16,
        height: 16,
    },
    weightSubmitButton: {
        width: '100%',
        marginTop: 34,
    },
    dialog: (_b = {
            width: 327,
            padding: '25px 24px 20px',
            borderRadius: 14
        },
        _b[MediaBreakPointUp.XL] = {
            width: 327,
        },
        _b),
    message: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.56,
        marginBottom: 20,
    },
    closeButton: {
        padding: 0,
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
        width: '100%',
        height: 48,
        borderRadius: 24,
    },
});
