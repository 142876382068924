import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Colors } from '../styles';
import { ChatbotTextLoadingIcon } from '../atoms/chatbot_text_loading_icon';
import { UnsubscribeChatbotChoiceButton } from '../molecules/unsubscribe_chatbot_choice_button';
export var UnsubscribeChatbotRow = function (_a) {
    var index = _a.index, chatbotContent = _a.chatbotContent, isLastContent = _a.isLastContent, isLoading = _a.isLoading, answeredIDs = _a.answeredIDs, onClickLink = _a.onClickLink, onClickChoice = _a.onClickChoice, onClickUnsubscribeButton = _a.onClickUnsubscribeButton;
    var isNoIconContent = chatbotContent.imageUrl || chatbotContent.isUnsubscribeButton;
    var ref = useRef(null);
    useEffect(function () {
        var current = ref.current;
        if (current)
            current.addEventListener('click', handleLinkClick);
        return function () {
            if (current)
                current.removeEventListener('click', handleLinkClick);
        };
    }, [ref.current]);
    var handleLinkClick = function (event) {
        var target = event.target;
        if (target.tagName === 'A') {
            event.preventDefault();
            var href = target.getAttribute('href');
            if (href) {
                onClickLink(chatbotContent.sectionID, chatbotContent.descriptionID);
                window.open(href, '_blank');
            }
        }
    };
    var onClickImage = function (event) {
        event.preventDefault();
        var href = event.currentTarget.getAttribute('href');
        if (href) {
            onClickLink(chatbotContent.sectionID, chatbotContent.descriptionID);
            window.open(href, '_blank');
        }
    };
    return (React.createElement("div", { className: css(styles.container, chatbotContent.isAnswer && styles.answerContainer) }, chatbotContent.isAnswer ? (React.createElement("div", { className: css(styles.answerText) }, chatbotContent.text)) : (React.createElement(React.Fragment, null,
        !isNoIconContent && React.createElement("img", { className: css(styles.icon), src: chatbotContent.iconURL() }),
        isLastContent && isLoading ? (React.createElement("div", { className: css(styles.text, isNoIconContent && styles.noIconLoadingText) },
            React.createElement(ChatbotTextLoadingIcon, { classObject: styles.loadingIcon }))) : chatbotContent.text ? (React.createElement("div", { className: css(styles.text) },
            React.createElement("div", { ref: ref, className: css(styles.contentText), dangerouslySetInnerHTML: { __html: chatbotContent.text } }))) : chatbotContent.imageUrl ? (React.createElement(React.Fragment, null, chatbotContent.isIframe() ? (React.createElement("div", { className: css(styles.image), dangerouslySetInnerHTML: { __html: chatbotContent.imageUrl } })) : (React.createElement(React.Fragment, null, chatbotContent.imageLinkUrl ? (React.createElement("a", { className: css(styles.link), href: chatbotContent.imageLinkUrl, target: "_blank", rel: "noopener noreferrer", onClick: onClickImage },
            React.createElement("img", { className: css(styles.imageWithLink), src: chatbotContent.imageURL() }))) : (React.createElement("img", { className: css(styles.image), src: chatbotContent.imageURL() })))))) : chatbotContent.isUnsubscribeButton ? (React.createElement("img", { className: css(styles.unsubscribeButton), src: "/assets/images/unsubscribe_chatbot/unsubscribe_button.png", onClick: function () { return onClickUnsubscribeButton(chatbotContent.descriptionID); } })) : (React.createElement("div", { className: css(styles.text) },
            React.createElement(UnsubscribeChatbotChoiceButton, { index: index, choices: chatbotContent.choices, disabled: isLoading, answeredIDs: answeredIDs, onClickChoice: onClickChoice })))))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        gap: 2,
    },
    answerContainer: {
        justifyContent: 'flex-end',
        marginLeft: 68,
    },
    answerText: {
        color: Colors.White,
        backgroundColor: Colors.Accent,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '160%',
        gap: 10,
        padding: '8px 14px',
        borderRadius: '12px 12px 0px 12px',
    },
    icon: {
        width: 48,
        height: 48,
    },
    text: {
        backgroundColor: Colors.CyanLightest,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        minHeight: 38,
        marginRight: 20,
        padding: '8px 14px',
        borderRadius: '0px 12px 12px 12px',
    },
    noIconLoadingText: {
        marginLeft: 50,
    },
    loadingIcon: {
        width: 51,
        height: 20,
    },
    contentText: {
        color: Colors.TextDark,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '160%',
    },
    link: {
        margin: '0 20px 0 50px',
    },
    imageWithLink: {
        maxWidth: '100%',
    },
    image: {
        maxWidth: 'calc(100% - 70px)',
        margin: '0 20px 0 50px',
    },
    unsubscribeButton: {
        width: 310,
        marginLeft: 50,
        cursor: 'pointer',
    },
});
