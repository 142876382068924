import * as uuid from 'uuid';
import { getCurrentUnixTimeInSeconds } from '../util/date';
export var SessionStorageType = {
    UuidSessionId: 'session_id',
    AamazonPayParams: 'amazon_pay_params',
    AndroidVersion: 'android_version',
    AndroidHasStepcoutPermission: 'android_has_stepcount_permission',
    AndroidHasWeightPermission: 'android_has_weight_permission',
    AndroidHasBodyFatPermission: 'android_has_bodyfat_permission',
    AndroidDeviceNotificationPermission: 'android_device_notification_permission',
    AndroidNotificationSettings: 'android_notification_settings',
    AndroidHasCameraPermission: 'android_has_camera_permission',
};
var SessionStorage = /** @class */ (function () {
    function SessionStorage() {
        Object.defineProperty(this, "sessionStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: sessionStorage
        });
        Object.defineProperty(this, "expiresSeconds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 3600 * 24
        });
    }
    Object.defineProperty(SessionStorage.prototype, "loadSession", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var data = this.sessionStorage.getItem(SessionStorageType.UuidSessionId);
            if (data) {
                var parsedData = JSON.parse(data);
                var expires = parsedData.expires;
                if (this.isExpired(expires)) {
                    return null;
                }
                return parsedData;
            }
            return null;
        }
    });
    /**
     * sessionを生成して返す
     */
    Object.defineProperty(SessionStorage.prototype, "setupSession", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var unixTs = getCurrentUnixTimeInSeconds();
            var params = {
                session_id: uuid.v4(),
                expire: unixTs + this.expiresSeconds,
            };
            this.sessionStorage.setItem(SessionStorageType.UuidSessionId, JSON.stringify(params));
            return params;
        }
    });
    Object.defineProperty(SessionStorage.prototype, "remove", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key) {
            this.sessionStorage.removeItem(key);
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAmazonPayParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var data = this.sessionStorage.getItem(SessionStorageType.AamazonPayParams);
            return JSON.parse(data);
        }
    });
    Object.defineProperty(SessionStorage.prototype, "setAmazonPayParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (planId, campaignCode) {
            var params = {
                planId: planId,
                campaignCode: campaignCode,
                queryParams: location.search,
            };
            this.sessionStorage.setItem(SessionStorageType.AamazonPayParams, JSON.stringify(params));
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidAppVersion", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var version = this.sessionStorage.getItem(SessionStorageType.AndroidVersion);
            return version;
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidStepcountPermission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var hasPermission = this.sessionStorage.getItem(SessionStorageType.AndroidHasStepcoutPermission);
            return Boolean(parseInt(hasPermission, 10));
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidAnyHealthConnectPermission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var hasStepPermission = Boolean(parseInt(this.sessionStorage.getItem(SessionStorageType.AndroidHasStepcoutPermission), 10));
            var hasWeightPermission = Boolean(parseInt(this.sessionStorage.getItem(SessionStorageType.AndroidHasWeightPermission), 10));
            var hasBodyFatPermission = Boolean(parseInt(this.sessionStorage.getItem(SessionStorageType.AndroidHasBodyFatPermission), 10));
            return hasStepPermission || hasWeightPermission || hasBodyFatPermission;
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidDeviceNotificationPermission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var hasPermission = this.sessionStorage.getItem(SessionStorageType.AndroidDeviceNotificationPermission);
            return hasPermission === 'true';
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidCameraPermission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var hasPermission = this.sessionStorage.getItem(SessionStorageType.AndroidHasCameraPermission);
            return hasPermission === 'true';
        }
    });
    Object.defineProperty(SessionStorage.prototype, "loadAndroidNotificationSettings", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var settings = this.sessionStorage.getItem(SessionStorageType.AndroidNotificationSettings);
            if (settings === null) {
                return null;
            }
            var parsed = JSON.parse(settings);
            // number（0 or 1）で保存されているので、booleanに変換する
            parsed.news = !!parsed.news;
            parsed.live = !!parsed.live;
            parsed.courseReminder = !!parsed.courseReminder;
            return parsed;
        }
    });
    Object.defineProperty(SessionStorage.prototype, "isExpired", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (expires) {
            var unixTs = getCurrentUnixTimeInSeconds();
            return expires < unixTs;
        }
    });
    return SessionStorage;
}());
export { SessionStorage };
