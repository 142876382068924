import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var PlanAmountText = function (_a) {
    var planName = _a.planName, plan = _a.plan;
    return (React.createElement("div", { className: css(styles.container) },
        "\u7121\u6599\u4F53\u9A13\u7D42\u4E86\u5F8C ",
        planName,
        " ",
        plan.amountWithoutTax.toLocaleString(),
        "\u5186\uFF08\u7A0E\u8FBC",
        plan.amount.toLocaleString(),
        "\u5186\uFF09",
        plan.isStandardPlan() && '/月',
        "\u306E\u3054\u767B\u9332",
        !plan.isStandardPlan() && (React.createElement(React.Fragment, null,
            React.createElement("br", null),
            "1\u30F6\u6708\u3042\u305F\u308A",
            plan.amountPerMonthWithoutTax.toLocaleString(),
            "\u5186/\u6708\uFF08\u7A0E\u8FBC",
            plan.amountPerMonth.toLocaleString(),
            "\u5186/\u6708\uFF09\u76F8\u5F53"))));
};
var styles = StyleSheet.create({
    container: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.5,
    },
});
