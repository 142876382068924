import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var ChatTextSendIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject, onClick = _a.onClick;
    var styles = StyleSheet.create({
        container: {
            width: 27,
            height: 26,
        },
    });
    var iconColor = color || Colors.Alert;
    return (React.createElement("svg", { className: css(styles.container, classObject), viewBox: "0 0 27 26", fill: "none", xmlns: "http://www.w3.org/2000/svg", onClick: onClick },
        React.createElement("path", { d: "M24.3024 12.6425C24.1504 11.7825 23.6459 11.2514 22.886 10.9237C18.1773 8.89206 13.4723 6.85052 8.76569 4.81394C7.6476 4.33036 6.53109 3.84235 5.40452 3.38032C4.86508 3.15926 4.31928 3.23055 3.80951 3.5102C2.87953 4.02031 2.49535 5.00793 2.77143 6.1326C3.09308 7.44132 3.41103 8.75058 3.73162 10.0593C3.90278 10.7595 4.07499 11.4598 4.24933 12.1672C4.36273 12.1119 4.48885 12.0798 4.62291 12.0798H12.0151C12.502 12.0798 12.8973 12.4916 12.8973 13C12.8973 13.5079 12.5026 13.9202 12.0151 13.9202H4.62291C4.48991 13.9202 4.36432 13.8887 4.25145 13.834C3.73957 15.9308 3.22874 18.0033 2.73063 20.078C2.5812 20.7014 2.6946 21.2933 3.05334 21.8189C3.64948 22.6932 4.63987 22.9806 5.62443 22.5578C7.51194 21.7482 9.39575 20.9286 11.2811 20.1128C15.1558 18.4361 19.0299 16.7587 22.9056 15.0841C23.3248 14.9029 23.7142 14.6713 23.9479 14.2596C24.1133 13.9683 24.2076 13.6328 24.3332 13.3173V12.6994C24.3226 12.6806 24.3056 12.6629 24.3024 12.6425Z", fill: iconColor })));
};
