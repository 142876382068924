var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { SessionStorage } from '@lean-body/src/infra';
import { Colors } from '../styles';
import { RecordMealActionType } from './record_meal';
import { CameraBorderIcon } from '../atoms/icons/camera_border_icon';
import { GalleryIcon } from '../atoms/icons/gallery_icon';
import { isAndroidApp, isNativeWeb } from '@lean-body/src/util';
import { RecordMealMenuSearch } from './record_meal_menu_search';
import { RecordMealMenuHistory } from './record_meal_menu_history';
import { MealRecordSheet } from '../molecules/meal_record_sheet';
import { MealHeader } from '../atoms/meal_header';
import { WebViewCallbackHandlers } from '@lean-body/src/interfaces';
import { Dialog } from '../molecules';
import { Button, ButtonType } from '../atoms';
export var RecordMealSelectForm = function (_a) {
    var headerTitle = _a.headerTitle, selectedMenuMap = _a.selectedMenuMap, selectedAnalysisMenuMap = _a.selectedAnalysisMenuMap, imageMap = _a.imageMap, mealType = _a.mealType, isNoInputData = _a.isNoInputData, setAction = _a.setAction, setAnalysisImage = _a.setAnalysisImage, onClickSelectMenu = _a.onClickSelectMenu, onClickDeselectMenu = _a.onClickDeselectMenu, onClickMenuDetail = _a.onClickMenuDetail, onClickBack = _a.onClickBack, onClickSkipMeal = _a.onClickSkipMeal, classObject = _a.classObject, apiClient = _a.apiClient, config = _a.config;
    var takePicktureRef = React.useRef(null);
    var selectAlbumRef = React.useRef(null);
    var sessionStorage = new SessionStorage();
    var _b = React.useState(false), isInputSearch = _b[0], setIsInputSearch = _b[1];
    var _c = React.useState(false), showCameraPermissionErrorDialog = _c[0], setShowCameraPermissionErrorDialog = _c[1];
    var _d = React.useState(false), showImageCountLimitErrorDialog = _d[0], setShowImageCountLimitErrorDialog = _d[1];
    var onFileUpload = function (event) {
        setAnalysisImage(event.target.files[0]);
        setAction(RecordMealActionType.analysis);
    };
    var onClickMenu = function (menu) {
        onClickMenuDetail(null, menu);
    };
    var onClickTakePicture = function () {
        if (validateImageCount()) {
            if (isAndroidApp() && !sessionStorage.loadAndroidCameraPermission()) {
                window.flutter_inappwebview.callHandler(WebViewCallbackHandlers.requestCameraPermission).then(function (_a) {
                    var result = _a.result;
                    if (result) {
                        takePicktureRef.current.click();
                    }
                    else {
                        setShowCameraPermissionErrorDialog(true);
                    }
                });
            }
            else {
                takePicktureRef.current.click();
            }
        }
        else {
            setShowImageCountLimitErrorDialog(true);
        }
    };
    var onClickSelectAlbum = function () {
        if (validateImageCount()) {
            selectAlbumRef.current.click();
        }
        else {
            setShowImageCountLimitErrorDialog(true);
        }
    };
    var validateImageCount = function () {
        return Array.from(imageMap.values()).filter(function (v) { return v.id === null; }).length < 8;
    };
    var onClickConfirm = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (isNoInputData)
                return [2 /*return*/];
            setAction(RecordMealActionType.confirm);
            return [2 /*return*/];
        });
    }); };
    var onClickAndroidSetting = function () {
        window.flutter_inappwebview.callHandler(WebViewCallbackHandlers.openLeanBodyAppSetting);
        setShowCameraPermissionErrorDialog(false);
    };
    var onCloseCameraPermissionErrorDialog = function () {
        setShowCameraPermissionErrorDialog(false);
    };
    var onCloseImageCountLimitErrorDialog = function () {
        setShowImageCountLimitErrorDialog(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MealHeader, { onClickBack: onClickBack }, headerTitle),
        React.createElement("div", { className: css(styles.container, classObject) },
            React.createElement(RecordMealMenuSearch, { apiClient: apiClient, config: config, selectedMenuMap: selectedMenuMap, onClickMenu: onClickMenu, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu, setIsInputSearch: setIsInputSearch }),
            !isInputSearch && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.analyzeImageGroup) },
                    (isNativeWeb() || isAndroidApp()) && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: css(styles.label), onClick: onClickTakePicture },
                            React.createElement("div", { className: css(styles.analyzeImageGroupButton) },
                                React.createElement("div", { className: css(styles.labelIcons) },
                                    React.createElement(CameraBorderIcon, null),
                                    React.createElement("img", { className: css(styles.newIcon), src: "assets/images/icons/new_icon.svg" })),
                                "\u5199\u771F\u3092\u64AE\u308B")),
                        React.createElement("input", { type: "file", onChange: onFileUpload, style: { display: 'none' }, accept: "image/*", capture: "environment", ref: takePicktureRef }))),
                    React.createElement("div", { className: css(styles.label), onClick: onClickSelectAlbum },
                        React.createElement("div", { className: css(styles.analyzeImageGroupButton) },
                            React.createElement("div", { className: css(styles.labelIcons) },
                                React.createElement(GalleryIcon, null),
                                React.createElement("img", { className: css(styles.newIcon), src: "assets/images/icons/new_icon.svg" })),
                            "\u30A2\u30EB\u30D0\u30E0\u304B\u3089\u9078\u629E")),
                    React.createElement("input", { type: "file", onChange: onFileUpload, style: { display: 'none' }, accept: "image/*", ref: selectAlbumRef })),
                React.createElement(RecordMealMenuHistory, { apiClient: apiClient, defaultMealType: mealType, selectedMenuMap: selectedMenuMap, onClickMenu: onClickMenu, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu }),
                isAndroidApp() && showCameraPermissionErrorDialog && (React.createElement(Dialog, { isCloseButton: true, borderRadius: 14, onClickCancel: onCloseCameraPermissionErrorDialog, content: React.createElement("div", { className: css(styles.errorDialog) },
                        React.createElement("div", { className: css(styles.dialogContent) },
                            React.createElement("div", { className: css(styles.dialogTitle) }, "\u30AB\u30E1\u30E9\u3092\u4F7F\u7528\u3067\u304D\u307E\u305B\u3093"),
                            React.createElement("div", { className: css(styles.dialogDescription) }, "LEAN BODY\u3067\u5199\u771F\u3092\u64AE\u5F71\u3059\u308B\u305F\u3081\u306B\u3001\u7AEF\u672B\u306E\u8A2D\u5B9A\u3067\u30AB\u30E1\u30E9\u3078\u306E\u30A2\u30AF\u30BB\u30B9\u3092\u8A31\u53EF\u3057\u3066\u304F\u3060\u3055\u3044\u3002")),
                        React.createElement(Button, { className: css(styles.dialogButton), isRadius: true, buttonType: ButtonType.primary, onClick: onClickAndroidSetting }, "\u8A2D\u5B9A\u753B\u9762\u3078")) })),
                showImageCountLimitErrorDialog && (React.createElement(Dialog, { borderRadius: 14, content: React.createElement("div", { className: css(styles.errorDialog) },
                        React.createElement("div", { className: css(styles.dialogContent) },
                            React.createElement("div", { className: css(styles.dialogDescription) }, "\u540C\u6642\u306B\u767B\u9332\u3067\u304D\u308B\u5199\u771F\u306E\u679A\u6570\u3092\u8D85\u3048\u307E\u3057\u305F\u3002\u5199\u771F\u3092\u8FFD\u52A0\u3059\u308B\u306B\u306F\u4E00\u5EA6\u767B\u9332\u3092\u5B8C\u4E86\u3057\u3001\u518D\u5EA6\u767B\u9332\u3057\u76F4\u3057\u3066\u304F\u3060\u3055\u3044\u3002")),
                        React.createElement(Button, { className: css(styles.dialogButton), isRadius: true, buttonType: ButtonType.primary, onClick: onCloseImageCountLimitErrorDialog }, "OK")) }))))),
        React.createElement(MealRecordSheet, { action: RecordMealActionType.select, selectedMenuMap: selectedMenuMap, selectedAnalysisMenuMap: selectedAnalysisMenuMap, onClickSkipMeal: onClickSkipMeal, onClickConfirm: onClickConfirm })));
};
var styles = StyleSheet.create({
    container: {},
    analyzeImageGroup: {
        display: 'flex',
        width: '100%',
        height: 70,
        gap: 8,
        marginTop: 12,
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    analyzeImageGroupButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.Black,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '100%',
        letterSpacing: 0.48,
        width: '100%',
        gap: 4,
        padding: '10px 27px 12px',
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 4,
        cursor: 'pointer',
    },
    labelIcons: {
        position: 'relative',
    },
    newIcon: {
        position: 'absolute',
        top: 0,
        right: -16,
    },
    errorDialog: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: 20,
        padding: '25px 24px 20px',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    dialogTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    dialogDescription: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    dialogButton: {
        width: '100%',
    },
});
