import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var ArrowDirection = {
    right: 'right',
    left: 'left',
    bottom: 'bottom',
    top: 'top',
};
var Arrowtransform = {
    top: 'rotate(0deg)',
    right: 'rotate(90deg)',
    bottom: 'rotate(180deg)',
    left: 'rotate(270deg)',
};
export var ArrowIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject, colorClassObject = _a.colorClassObject, direction = _a.direction, onClick = _a.onClick;
    var styles = StyleSheet.create({
        icon: {
            width: 16,
            height: 10,
            transform: Arrowtransform[direction || ArrowDirection.top],
            transition: 'transform 0.2s',
            cursor: onClick ? 'pointer' : 'unset',
        },
    });
    var iconColor = color || Colors.Primary;
    return (React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 16 10", fill: "none", xmlns: "http://www.w3.org/2000/svg", onClick: onClick },
        React.createElement("path", { className: css(colorClassObject), fill: iconColor, d: "M0.74 7.82242L7.37 1.19992C7.7225 0.847422 8.285 0.847422 8.6375 1.19992L15.26 7.82242C15.455 8.01742 15.455 8.32492 15.26 8.51992L14.5325 9.24742C14.3375 9.44242 14.03 9.44242 13.835 9.24742L8 3.41992L2.165 9.25492C1.97 9.44992 1.6625 9.44992 1.4675 9.25492L0.74 8.52742C0.545001 8.33242 0.545001 8.01742 0.74 7.82242Z" })));
};
