var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { DailyTasksPageTaskName } from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite';
import { Task } from '@lean-body/src/domain';
import { Colors, MediaBreakPointUp } from '../styles';
import { ActivityTabs, CHART_LENGTH_FOR_MONTHLY, CHART_LENGTH_FOR_QUARTERLY, CHART_LENGTH_FOR_WEEKLY, LifetimeActivityChart, LifetimeActivityType, LifetimeActivityUnit, } from './lifetime_activity_chart';
import { DailyHistory } from '../molecules';
import dayjs from 'dayjs';
import { getFormattedToFormat } from '@lean-body/src/util';
import { ActivityDiff } from './activity_diff';
import { DatePaginationFirstPage } from '../molecules/date_pagination';
export var fetchDataForWorkout = function (tab, apiClient, goalDate) { return __awaiter(void 0, void 0, void 0, function () {
    var promises;
    return __generator(this, function (_a) {
        promises = [
            apiClient.fetchDailyTaskGoal(DailyTasksPageTaskName.workout, getFormattedToFormat(goalDate)),
            tab === ActivityTabs.weekly
                ? apiClient.fetchWeeklyActivity(goalDate, DatePaginationFirstPage)
                : tab === ActivityTabs.monthly
                    ? apiClient.fetchMonthlyActivity(goalDate, DatePaginationFirstPage)
                    : apiClient.fetchThreeMonthActivity(goalDate, DatePaginationFirstPage),
            tab === ActivityTabs.weekly
                ? apiClient.fetchWorkoutCalories(goalDate, CHART_LENGTH_FOR_WEEKLY)
                : tab === ActivityTabs.monthly
                    ? apiClient.fetchWorkoutCalories(goalDate, CHART_LENGTH_FOR_MONTHLY)
                    : apiClient.fetchWorkoutCalories(goalDate, CHART_LENGTH_FOR_QUARTERLY),
        ];
        return [2 /*return*/, Promise.all(promises)];
    });
}); };
export var fetchDataForSteps = function (tab, apiClient, goalDate) { return __awaiter(void 0, void 0, void 0, function () {
    var promises;
    return __generator(this, function (_a) {
        promises = [
            apiClient.fetchDailyTaskGoal(DailyTasksPageTaskName.steps, getFormattedToFormat(goalDate)),
            tab === ActivityTabs.weekly
                ? apiClient.fetchWeeklyActivity(goalDate, DatePaginationFirstPage)
                : tab === ActivityTabs.monthly
                    ? apiClient.fetchMonthlyActivity(goalDate, DatePaginationFirstPage)
                    : apiClient.fetchThreeMonthActivity(goalDate, DatePaginationFirstPage),
            tab === ActivityTabs.weekly
                ? apiClient.fetchStepActivities(goalDate, CHART_LENGTH_FOR_WEEKLY)
                : tab === ActivityTabs.monthly
                    ? apiClient.fetchStepActivities(goalDate, CHART_LENGTH_FOR_MONTHLY)
                    : apiClient.fetchStepActivities(goalDate, CHART_LENGTH_FOR_QUARTERLY),
        ];
        return [2 /*return*/, Promise.all(promises)];
    });
}); };
export var fetchDataForMealCalories = function (tab, apiClient, goalDate) { return __awaiter(void 0, void 0, void 0, function () {
    var promises;
    return __generator(this, function (_a) {
        promises = [
            apiClient.fetchDailyTaskGoal(DailyTasksPageTaskName.meal, getFormattedToFormat(goalDate)),
            tab === ActivityTabs.weekly
                ? apiClient.fetchMealCalories(goalDate, CHART_LENGTH_FOR_WEEKLY)
                : tab === ActivityTabs.monthly
                    ? apiClient.fetchMealCalories(goalDate, CHART_LENGTH_FOR_MONTHLY)
                    : apiClient.fetchMealCalories(goalDate, CHART_LENGTH_FOR_QUARTERLY),
        ];
        return [2 /*return*/, Promise.all(promises)];
    });
}); };
export var LifetimeTaskActivity = function (_a) {
    var activityType = _a.activityType, apiClient = _a.apiClient, tab = _a.tab, goalDate = _a.goalDate, classObject = _a.classObject;
    var _b = React.useState(null), activity = _b[0], setActivity = _b[1];
    var _c = React.useState([]), workoutCalories = _c[0], setWorkoutCalories = _c[1];
    var _d = React.useState([]), stepActivities = _d[0], setStepActivities = _d[1];
    var _e = React.useState([]), mealCalories = _e[0], setMealCalories = _e[1];
    var _f = React.useState(), activityValue = _f[0], setActivityValue = _f[1];
    var _g = React.useState(), activityDiff = _g[0], setActivityDiff = _g[1];
    var _h = React.useState(null), goalByDate = _h[0], setGoalByDate = _h[1];
    var _j = React.useState([]), graphScales = _j[0], setGraphScales = _j[1];
    var lastFetchTime = React.useRef(0);
    // Promiseの解決順序が保証されないのでfetchTimeと比べて
    // 最後に実行されたPromiseの結果だけ使う
    React.useEffect(function () {
        var fetchTime = Date.now();
        lastFetchTime.current = fetchTime;
        if (activityType === LifetimeActivityType.workoutCalorie) {
            fetchDataForWorkout(tab, apiClient, goalDate).then(function (_a) {
                var goal = _a[0], activity = _a[1], workoutCalories = _a[2];
                if (fetchTime === lastFetchTime.current) {
                    setGoalByDate(goal);
                    setActivity(activity);
                    setWorkoutCalories(workoutCalories.workoutCalorieList);
                    setGraphScales(workoutCalories.graphScales);
                }
            });
        }
        else if (activityType === LifetimeActivityType.steps) {
            fetchDataForSteps(tab, apiClient, goalDate).then(function (_a) {
                var taskGoal = _a[0], activity = _a[1], stepActivities = _a[2];
                if (fetchTime === lastFetchTime.current) {
                    setGoalByDate(taskGoal);
                    setActivity(activity);
                    setStepActivities(stepActivities.stepActivityList);
                    setGraphScales(stepActivities.graphScales);
                }
            });
        }
        else if (activityType === LifetimeActivityType.mealCalorie) {
            fetchDataForMealCalories(tab, apiClient, goalDate).then(function (_a) {
                var goal = _a[0], activity = _a[1];
                if (fetchTime === lastFetchTime.current) {
                    setGoalByDate(goal);
                    setMealCalories(activity.mealCalories);
                    setActivityValue(activity.averageCalorie);
                    setActivityDiff(activity.lastTimeDiff);
                    setGraphScales(activity.graphScales);
                }
            });
        }
    }, [tab, goalDate]);
    return (React.createElement("div", { className: css(classObject) },
        React.createElement(LifetimeActivityChart, { tab: tab, stepActivities: stepActivities, target: activityType, workoutCalories: workoutCalories, mealCalories: mealCalories, weightsAndBodyFatActivities: [], rightmostDate: goalDate, horizontalLineValue: Task.roundTaskCalories(goalByDate), leftGraphScales: graphScales, classObject: styles.chart }),
        React.createElement(ActivityDiff, { activityType: activityType, activity: activity, activityValue: activityValue, activityDiff: activityDiff }),
        React.createElement("div", { className: css(styles.dailyHistories) },
            activityType === LifetimeActivityType.workoutCalorie && (React.createElement(React.Fragment, null, workoutCalories === null || workoutCalories === void 0 ? void 0 : workoutCalories.map(function (v, i) { return (React.createElement(DailyHistory, { key: i, date: dayjs(v.date), value: "".concat(Task.roundTaskCalories(v.calories).toLocaleString()).concat(LifetimeActivityUnit.workoutCalorie), classObject: workoutCalories.length - 1 !== i && styles.dailyHistory })); }))),
            activityType === LifetimeActivityType.steps && (React.createElement(React.Fragment, null, stepActivities === null || stepActivities === void 0 ? void 0 : stepActivities.map(function (v, i) { return (React.createElement(DailyHistory, { key: i, date: dayjs.unix(v.dateAt), value: "".concat(Task.roundTaskCalories(v.steps).toLocaleString()).concat(LifetimeActivityUnit.steps), classObject: stepActivities.length - 1 !== i && styles.dailyHistory })); }))),
            activityType === LifetimeActivityType.mealCalorie && (React.createElement(React.Fragment, null, mealCalories === null || mealCalories === void 0 ? void 0 : mealCalories.map(function (v, i) { return (React.createElement(DailyHistory, { key: i, date: dayjs.unix(v.date), value: "".concat(Task.roundTaskCalories(v.calories).toLocaleString()).concat(LifetimeActivityUnit.mealCalorie), classObject: mealCalories.length - 1 !== i && styles.dailyHistory })); }))))));
};
var styles = StyleSheet.create({
    chart: {
        width: '100%',
    },
    dailyHistories: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 327,
            margin: '0 auto',
        },
        _a),
    dailyHistory: {
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
});
